/*********************************************************************************************************************************************/
/* LAYOUT */
/*********************************************************************************************************************************************/
@media (min-width: 992px) {
  .container {
      max-width: 800px;
  }
}




.card {
  margin-bottom: 20px;
}



/*********************************************************************************************************************************************/
/* REPOSITORIO */
/*********************************************************************************************************************************************/
hr.dots {
  width: 100%;
  border: 0;
  border-top: 1px dashed #c6b3a0;
  display: block;
}

#game-headline
  margin-top: 4px
  &.canceled
    border-top: 3px solid $color-danger !important
  &.closed
    border-top: 3px solid $color-dark !important
  &.played
    border-top: 3px solid $color-info !important

.match-in-list
  position: relative
  &.juegas
    border-color: $color-primary-light
  .date-places
    position: relative
    .match-date
      padding-right: 85px
    .lugares
      white-space: nowrap
      top: -2px
      right: 0
      position: absolute
  .match-title
    font-size: 1rem
    color: $color-dark
    margin-bottom: 0.5rem
    



.share-match {
  float: right;
  z-index: 10;
  position: relative;
}
.match-in-list .share-match {
  margin: -26px -13px 0 0;
}
.match-in-list #partido-ficha .share-match {
  margin: -9px -0px 0 0;
}


.games-listed

.column-match
  margin-bottom: 30px;

.calendar
  display: flex
  background-color: white !important
  border-bottom: 1px solid $color-grey-1
  border-right: 1px solid $color-grey-1
  border-top: 1px solid $color-grey-1
  border-left: 5px solid $color-secondary-light
  align-items: center
  position: sticky
  top: 80px;
  z-index: 1
  padding: 0.5rem
  background: white;
  width: 100%
  margin: 0 0 20px 0
  &.calendar-game
    top: unset
    flex-direction: row
  .jugado
    opacity: 0.3
  span
    display: block
    text-align: center
    text-transform: uppercase
    font-weight: bold
    font-size: 0.9rem
    letter-spacing: -0.05rem
    margin-right: 3px
    &.day
      color: $color-danger
      font-weight: 900
    &.month
    &.weekday
      color: $color-text

@media (min-width: 600px)
  .games-listed
    display: flex
    flex-wrap: wrap
  .calendar
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075) !important
    background-color: white !important
    border-bottom: 1px solid $color-grey-1
    border-left: 1px solid $color-grey-1
    border-right: 1px solid $color-grey-1
    border-top: 5px solid $color-secondary-light
    width: 55px
    margin: 0 10px 0 0
    flex-direction: column
    .weekday
      display: none
    span
      margin-right: unset
      &.day
        font-size: 1.6rem
      &.month
      &.weekday
        margin-top: -4px

@media (min-width: 768px)
  .games-listed
    .calendar
      top: 140px




// FICHA DE PARTIDO
.match-title-date {
  display: flex;
}


small.pos-btn  {
  text-align: center;
  color: #292929;
  letter-spacing: 0;
  text-transform: uppercase;
  display: block;
  margin-top: 5px;
}






.stats-plus-label {
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-around;
}
.stats-plus-label li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stats-plus-label p {
  font-size: 2.2rem;
  font-weight: 900;
  margin-bottom: 0;
}
.stats-plus-label.reduced p {
  font-size: 1.8rem;
}
.stats-plus-label span {
  font-size: 0.8rem;
  font-weight: 900;
}
.stats-plus-label img {
  max-width: 16px;
  max-height: 16px; 
}
.stats-plus-label .muted {
  opacity: 0.3;
} 


.field-position
    position: relative
    &::after
        background: $color-dark
        color: white
        text-transform: uppercase
        font-size: 0.694rem
        font-weight: bolder
        padding: 2px 5px
        border-radius: 4px
        z-index: 10
        display: inline-flex
        align-items: center
        justify-content: center
        position: absolute
        top: 25px
        transform: translate(-50%, -50%)
        box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.5)
    &.no-position
        &::after
            content: "Posición no ingresada"
            background: white
            color: black
            left: 50%
    &.Arquero
        &::after
            content: "Arquero"
            transform: translate(0%, -50%)
            left: 10%
    &.Defensor
        &::after
            content: "Defensor"
            left: 48%
            top: 5px
            transform: translate(-100%, -50%)
            box-shadow: 0 36px 14px -5px rgba(0, 0, 0, 0.25)
    &.Mediocampista
        &::after
            content: "Mediocampista"
            left: 52%
            top: 5px
            transform: translate(0%, -50%)
            box-shadow: 0 36px 14px -5px rgba(0, 0, 0, 0.25)
    &.Delantero
        &::after
            content: "Delantero"
            transform: translate(-100%, -50%)
            left: 90%


@media only screen and (min-width: 600px)
    .field-position
        position: relative
        &::after
            font-size: 0.8rem
            padding: 5px 10px
            top: 32px
            box-shadow: 0 20px 10px -10px rgba(0, 0, 0, 0.65)
        &.no-position
            &::after
                left: 50%
        &.Arquero
            &::after
                left: 10%
        &.Defensor
            &::after
                top: 0px
                left: 45%
                box-shadow: 0 50px 14px -10px rgba(0, 0, 0, 0.5)
        &.Mediocampista
            &::after
                top: 0px
                left: 55%
                box-shadow: 0 50px 14px -10px rgba(0, 0, 0, 0.5)
        &.Delantero
            &::after
                left: 90%



.badges-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    // grid-template-rows: 60px 60px 60px; 
    column-gap: 1rem;
    row-gap: 1rem;
}
// .badges-grid img:first-child {
//     grid-column: 1 / 2;
// }
// .badges-grid img:nth-of-type(2) {
//     grid-column: 2 / 4;
// }

.badges-grid img {
  margin: 0 auto;  
}
@media only screen and (min-width: 475px){
    .badges-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    // .badges-grid img:first-child {
    //     grid-column: 3 / 4;
    // }
    // .badges-grid img:nth-of-type(2) {
    //     grid-column: 4 / 7;
    // }
}

/*********************************************************************************************************************************************/
/*********************************************************************************************************************************************/
/* PERFIL */
/*********************************************************************************************************************************************/

#perfil .user-header {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
#perfil .user-header .actions-profile {
  position: absolute;
  left: calc( 50% + 40px);
  top: 0;
}

#perfil .user-header {
  margin-left: auto !important;
  margin-right: auto !important;
}
#perfil .user-header p {
  color: $color-grey-4;
}
#perfil .menu-slide-item .dropdown-menu {
  top: 30px !important;
}

#profile-completion .tasks { 
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  row-gap: 0.5rem;
}
#profile-completion .tasks li i {
    color: $color-primary;
}
#profile-completion .tasks span,
#profile-completion .tasks a {
    text-align: left;
    font-size: 0.833rem;
    font-weight: bold;
}
#profile-completion .tasks :not(.task-unchecked) span {
    color: $color-text-muted;
}
#profile-completion .tasks .task-unchecked i {
  opacity: 0.2;
}

#badges img {
  width: 50px;
  height: 50px; 
}

@media only screen and (min-width: 600px){
  #perfil .menu-slide-item .dropdown-menu {
    top: 0px !important;
  }
  #profile-completion .tasks { 
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 768px){
  #perfil .user-header .user-info p {
    font-size: 1rem;
  }

  #profile-completion .tasks {
    grid-template-columns: 1fr 1fr 1fr;
  }
}






/*********************************************************************************************************************************************/
/*********************************************************************************************************************************************/
/* GAME */
/*********************************************************************************************************************************************/
.confirmados-game {
  display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 10px;
    padding: 5px 10px;
    round: 0.25rem;
}
.confirmados-game a {
  flex-grow: 1;
}
.confirmados-game i,
.confirmados-game a {
  color: rgba(0,0,0,0.7) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.w-icon {
  display: flex;
  align-items: center;
}

.w-icon i {
  margin-right: 3px;
}
.w-icon :not(i) {
  flex-grow: 1;
  margin-bottom: 0;
}

/*********************************************************************************************************************************************/
/*********************************************************************************************************************************************/
/* EQUIPOS */
/*********************************************************************************************************************************************/

.availability-games {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
}
@media only screen and (min-width: 600px){
    .availability-games {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

.availability-games .entry {
    display: inline-flex;
    flex: 1;
    border: 1px solid #2e8149;
    padding: .75rem .25rem;
    border-radius: 0.5rem;
    flex-direction: column;
    align-items: center;
}
.availability-games .entry .title {
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
}
.availability-games .entry .value {
    font-size: 0.8rem;
    font-weight: bolder;
}




/*********************************************************************************************************************************************/
/*********************************************************************************************************************************************/
/* CANCHAS */
/*********************************************************************************************************************************************/
.ciudades-canchas ul {
	list-style-type: none;
    padding: 0;
    margin-bottom: 0;
}
.ciudades-canchas .card-body li {
    border-top: 1px solid #e1e1e1;
    padding: 10px 0;
}
.ciudades-canchas .card-body li:first-child {
    border: 0;
    padding-top: 0;
}
.ciudades-canchas .card-body li:last-child {
    padding-bottom: 0;
}

#cancha-ficha .venue-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


@media only screen and (min-width: 600px){
  #cancha-ficha .venue-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
#cancha-ficha .venue-actions div {
  text-align: center;
  margin-bottom: 20px;
}


/*********************************************************************************************************************************************/
/* FAQS */
/*********************************************************************************************************************************************/
.pregunta-item
  box-shadow: 0 0 !important
  &::before
    content: ""
    display: block
    width: 100%
    height: 100%
    position: absolute
    box-shadow: 0 5px 45px 0 rgba(0,0,0,0.15)
    opacity: 0
    transition: opacity 0.2s ease-in-out
  &.border-0::before
    opacity: 1

/*********************************************************************************************************************************************/
/*********************************************************************************************************************************************/
/*********************************************************************************************************************************************/
/*********************************************************************************************************************************************/
/*********************************************************************************************************************************************/
/* MEDIA QUERIES GENERALES */
/*********************************************************************************************************************************************/


@media (min-width: 993px) {
  .content {
    min-height: 50%;
  }
}