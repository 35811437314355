.games-on-venue {
  -webkit-overflow-scrolling: touch;
  width: 100%;
}
.games-on-venue .placeholder {
  background: white; 
  border: 2px dashed #dedede;
  position: relative;
}
.games-on-venue .placeholder a {
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.games-on-venue .placeholder {
  height: calc(100% - 30px);
  //min-height: 200px;
}

@media only screen and (max-width: 991px){
  .games-on-venue {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
@media only screen and (min-width: 992px){
    .game-on-venue .match-in-list .match-title-date {
        height: 100px;
    }
    .games-on-venue {
      display: flex;
      flex-wrap: wrap;
    }
}


.table-hover tbody tr:hover {
    background-color: rgba(0,0,0,.075);
}

.reservations
  .nav-link
    height: 100%
    padding: 1rem

.reservation-table
  border: 1px solid $color-grey-1
  border-top: 0
  tbody
    tr
      height: 70px
      &:nth-of-type(odd)
        background-color: rgba(0,0,0,.025);
      &:hover
        background-color: rgba(145, 185, 113, 0.3);
  th
    border-bottom: 1px solid $color-grey-1 !important
    border-top: 0 !important
    font-size: 0.8rem
    background: white
  td
    font-size: 14px
  th
  td
    white-space: nowrap
    vertical-align: middle


#dashboard
  .card
    height: calc(100% - 20px)

.button-select
  display: flex
  gap: 0.25rem
  .as-btn
    flex: 1 1
    text-align: center
    margin: 0
    span
      font-size: 1rem


.selected-calendar
  cursor: pointer
  display: flex
  align-items: center
  border-bottom: 1px solid $color-grey-1
  border-left: 1px solid $color-grey-1
  border-right: 1px solid $color-grey-1
  border-top: 5px solid $color-secondary-light
  position: sticky
  top: 80px;
  z-index: 100
  padding: 0.25rem
  background: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075) !important
  width: 55px
  margin-right: 0.5rem
  flex-direction: column
  span
    display: block
    text-align: center
    text-transform: uppercase
    font-weight: bold
    letter-spacing: -0.05rem
    &.weekday
      font-size: 0.75rem !important
    &.day
      color: #c63e25
      font-size: 1.6rem !important
      margin-top: -0.25rem
    &.month
      color: #716664
      margin-top: -4px
      font-size: 0.8rem !important


.sidebar-right
  display: none
  z-index: 2
  background-color: rgba(255, 255, 255, 0.9)
  position: fixed
  top: 0
  bottom: 0
  right: 0
  left: 0
  .sidebar-body 
    background-color:white
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)
    width: 100%
    max-width: 500px
    position: fixed
    z-index: 1000
    top: 60px
    right: 0
    bottom: 60px
    overflow-y: auto
    padding: 1rem

@media (min-width: 768px)
  .sidebar-right
    .sidebar-body
      background: $color-background
      bottom: 0
      padding: 1rem 2rem

//// AGENDA AND CALENDAR
.schedule-container
  width: 100%
  min-height: 100vh
  button
    cursor: pointer
  #sticky-bar
    .sticky-body
      width: 100%
      display: flex
      flex-direction: row-reverse
      select
        width: 100% 
        font-weight: bolder
        // margin-bottom: 0.5rem
  .navigation
    display: flex
    //align-items: center
    button
      margin-right: 0.5rem
  .schedule
    width: 100%
    max-width: 800px
    margin: 3rem auto 0
    padding: 0 1rem
    .day-schedule
      .appointment-card
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        color: $color-dark
        padding: 0.5rem
        border-radius: 0.25rem
        font-weight: bold
        > div
          display: flex
          justify-content: space-between
          align-items: center
          flex-grow: 1
          width: 100%
          .info-reservation
            flex-grow: 1
            margin-left: 0.5rem
            margin-bottom: 0
            span
              b
                font-size: 0.8rem
          > .btn
            box-shadow: 0 2px 5px rgba(0,0,0,.35);
        &.slot-unavailable
          background-color: $color-grey-1 !important
          background: repeating-linear-gradient(-45deg,transparent, transparent 4px, $color-grey-2 5px, $color-grey-2 7px)
          &::after
            content: "Fuera de hora"
            color: $color-grey-5
            font-size: 0.75rem
            padding: 0.25rem 0.5rem
            background-color: $color-grey-1 !important
            border-radius: 0.25rem
            border: 1px solid $color-grey-2
        &.appointment-is-past
          &:not(.slot-unavailable)
            background-color: $color-grey-2
            &:not(.slot-available)
              background-color: $color-grey-1
        &:not(.appointment-is-past)
          &.slot-available
            >div
              &:first-child
                width: 100%
                > button
                  width: 100%
          &:not(.slot-unavailable)
            background-color: $color-background-green
            &.slot-reserved
              background-color: white
              border: 1px solid $color-grey-2
        .modal-background
          z-index: 1000
          background-color: rgba(255, 255, 255, 0.9)
          position: fixed
          top: 0
          bottom: 0
          right: 0
          left: 0
          display: flex
          justify-content: center
          align-items: center
          #edit-modal
            background-color:white
            width: 100%
            max-width: 500px
            position: fixed
            top: 0
            right: 0
            bottom: 0
            overflow-y: auto
            padding: 2rem
            color: #222
            padding-bottom: 4rem
            .edit-modal-header
              display: flex
              justify-content: space-between
              align-items: center
            form
              .form-100
                margin-bottom: 1rem


            
@media (min-width: 600px)
  .appointment-card
    > div
      .info-reservation
        span
          b
            font-size: 1rem !important




//// TABLE FOR FREE VS PRO
.container__premium {
    display: flex;
    flex-direction: column;
}

.container__premium .more-info {
  margin: 0 0 1rem;
  font-size: 0.9rem;
  text-align: left;
  color: $color-text-muted;
}
.container__premium .more-info p {
  margin-bottom: 0.75rem;
}
.container__premium .more-info p:last-child {
  margin-bottom: 0;
}

.container__row {
  align-items: center;
  display: flex;
  padding: 1rem 0;
  }
.container__row:not(:first-child) {
  border-top: 1px solid $color-grey-1;
}
.container__row.blank {
  //background: $alert-background-success;
  //color: $alert-color-success;
  justify-content: center;
  padding: 1.5rem 0;
}


.container__feature {
    /* Take available width */
    text-align: left;
    flex: 1;
    font-size: 0.8rem;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
}
.container__feature span {
  white-space: normal;
  text-align: left;
  margin-left: 0.5rem;
}

.container__model {
    /* Center the content */
    display: flex;
    justify-content: center;
    min-width: 25%;

    /* Spacing */
    font-size: 0.8rem;
    margin-left: 1rem;
}
