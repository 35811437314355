#notas
    .card
        height: calc(100% - 20px)

.tema-nota_image
    max-height: 300px
    width: 100%
    overflow: hidden
    position: relative
.tema-nota_header
    display: flex
    flex-direction: column
    justify-content: space-between
    background-color: white
    border-radius: 1rem 1rem 0 0
    .tema-nota_headline
        font-size: 2.2rem
        line-height: 100%
        margin-bottom: 2rem
    .tema-nota_intro
        font-size: 1.3rem
        color: $color-grey-5
    &.w-image
        margin-top: -100px !important

.tema-nota_header
.tema-nota_body
    padding: 1rem 2rem


@media (min-width: 768px)
    .tema-nota_image
        height: 400px
        max-height: unset
        img
            position: absolute
            transform: translate(-50%, -50%)
            min-height: 100%;
            top: 50%
            left: 50%
    .tema-nota_header
        &.w-image
            margin-top: -150px !important
        .tema-nota_headline
            font-size: 2.7rem