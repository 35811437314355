
@font-face {
  font-family: 'icon-don';
  src:  url('../fonts/icon-don.eot?9oloq1');
  src:  url('../fonts/icon-don.eot?9oloq1#iefix') format('embedded-opentype'),
    url('../fonts/icon-don.ttf?9oloq1') format('truetype'),
    url('../fonts/icon-don.woff?9oloq1') format('woff'),
    url('../fonts/icon-don.svg?9oloq1#icon-don') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i.icon-don {
  font-family: 'icon-don' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;
  color: $color-grey-4;

  display: inline-block;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
i.icon-don.icon-sm {
  font-size: 16px !important;
}
i.icon-don.icon-md {
  font-size: 24px !important;
}
i.icon-don.icon-lg {
  font-size: 32px !important;
}
i.icon-don.icon-xl {
  font-size: 48px !important;
}


.icon-about:before {
  content: "\e900";
}
.icon-action-add:before {
  content: "\e901";
}
.icon-action-close:before {
  content: "\e904";
}
.icon-action-confirm:before {
  content: "\e906";
}
.icon-action-delete:before {
  content: "\e903";
}
.icon-action-edit:before {
  content: "\e905";
}
.icon-action-more:before {
  content: "\e953";
}
.icon-action-remove:before {
  content: "\e907";
}
.icon-arrow-chevron-down:before {
  content: "\e908";
}
.icon-arrow-chevron-left:before {
  content: "\e909";
}
.icon-arrow-chevron-right:before {
  content: "\e90a";
}
.icon-arrow-chevron-up:before {
  content: "\e90b";
}
.icon-arrow-point-left:before {
  content: "\e90c";
}
.icon-arrow-point-down:before {
  content: "\e913";
}
.icon-arrow-point-right:before {
  content: "\e922";
}
.icon-arrow-point-up:before {
  content: "\e937";
}
.icon-calendar:before {
  content: "\e90d";
}
.icon-calendar-ok:before {
  content: "\e90e";
}
.icon-calendar-x:before {
  content: "\e90f";
}
.icon-challenges:before {
  content: "\e954";
}
.icon-comments:before {
  content: "\e910";
}
.icon-community:before {
  content: "\e911";
}
.icon-dashboard:before {
  content: "\e912";
}
.icon-dropdown:before {
  content: "\e94a";
}
.icon-enter:before {
  content: "\e914";
}
.icon-exit:before {
  content: "\e915";
}
.icon-faqs:before {
  content: "\e916";
}
.icon-favourite-off:before {
  content: "\e94b";
}
.icon-favourite-on:before {
  content: "\e94e";
}
.icon-field-position:before {
  content: "\e918";
}
.icon-fields:before {
  content: "\e919";
}
.icon-filters:before {
  content: "\e91a";
}
.icon-football-type:before {
  content: "\e91b";
}
.icon-friends:before {
  content: "\e91c";
}
.icon-games:before {
  content: "\e91d";
}
.icon-gender:before {
  content: "\e91e";
}
.icon-gender-femenine:before {
  content: "\e91f";
}
.icon-gender-masculine:before {
  content: "\e920";
}
.icon-help:before {
  content: "\e921";
}
.icon-info:before {
  content: "\e923";
}
.icon-landing:before {
  content: "\e924";
}
.icon-light:before {
  content: "\e925";
}
.icon-link:before {
  content: "\e926";
}
.icon-list:before {
  content: "\e927";
}
.icon-load-download:before {
  content: "\e956";
}
.icon-load-upload:before {
  content: "\e957";
}
.icon-location:before {
  content: "\e928";
}
.icon-lock-off:before {
  content: "\e958";
}
.icon-lock-on:before {
  content: "\e959";
}
.icon-mail:before {
  content: "\e92a";
}
.icon-menu:before {
  content: "\e92b";
}
.icon-money:before {
  content: "\e92c";
}
.icon-notification:before {
  content: "\e95a";
}
.icon-personality:before {
  content: "\e92d";
}
.icon-phone:before {
  content: "\e92e";
}
.icon-posts:before {
  content: "\e92f";
}
.icon-privacy:before {
  content: "\e930";
}
.icon-ratings:before {
  content: "\e931";
}
.icon-reports:before {
  content: "\e932";
}
.icon-reservation:before {
  content: "\e933";
}
.icon-reservations-log:before {
  content: "\e934";
}
.icon-roof:before {
  content: "\e935";
}
.icon-search:before {
  content: "\e936";
}
.icon-settings:before {
  content: "\e955";
}
.icon-share:before {
  content: "\e95b";
}
.icon-skill:before {
  content: "\e938";
}
.icon-social-facebook:before {
  content: "\e939";
}
.icon-social-instagram:before {
  content: "\e93a";
}
.icon-social-twitter:before {
  content: "\e93b";
}
.icon-social-whatsapp:before {
  content: "\e93c";
}
.icon-social-youtube:before {
  content: "\e93d";
}
.icon-sort-asc:before {
  content: "\e93e";
}
.icon-sort-desc:before {
  content: "\e93f";
}
.icon-status-error:before {
  content: "\e940";
}
.icon-status-info:before {
  content: "\e941";
}
.icon-status-success:before {
  content: "\e942";
}
.icon-status-warning:before {
  content: "\e943";
}
.icon-store:before {
  content: "\e944";
}
.icon-surface:before {
  content: "\e945";
}
.icon-teams:before {
  content: "\e946";
}
.icon-terms:before {
  content: "\e947";
}
.icon-time:before {
  content: "\e948";
}
.icon-unknown-player:before {
  content: "\e949";
}
.icon-user:before {
  content: "\e94c";
}
.icon-user-admin:before {
  content: "\e94d";
}
.icon-user-no-team:before {
  content: "\e902";
}
.icon-user-ok:before {
  content: "\e94f";
}
.icon-venue:before {
  content: "\e950";
}
.icon-visibility-off:before {
  content: "\e95c";
}
.icon-visibility-on:before {
  content: "\e95d";
}
.icon-window-maximize:before {
  content: "\e951";
}
.icon-window-minimize:before {
  content: "\e952";
}