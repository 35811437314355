
.estado-convocatoria
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    &.en-ficha
        border-bottom: 1px solid #dfdfdf
        margin: -20px -20px 10px
        padding: 10px 20px 10px
        background-color: #f9f9f9
    .game-row 
        text-align: left
        margin-right: 20px
        flex-shrink: 0


.invite-response
    display: flex
    a
    span
        width: 40px
        height: 40px
        display: flex
        align-items: center
        justify-content: center
        border-radius: 50%
        background: white
        border: 1px solid $color-grey-1
        &:first-child
            margin-right: 5px
        i
            margin: 0 !important
        &#reject-link
            i
                color: $color-danger !important
            &.selected
                background-color: $alert-background-danger
                border-color: $alert-border-danger
        &#accept-link
            i
                color: $color-primary !important
            &.selected
                background-color: $alert-background-success
                border-color: $alert-border-success

 @media (min-width: 768px)
    .card-body
        > .invite-answer
            flex-direction: row
            justify-content: space-between
            span
                display: inline-block
                margin-bottom: 0
            .invite-response
                text-align: left

/* MARCADORES ***************************/
.scores
    display: flex
    flex-direction: column
    flex-wrap: wrap
    color: $color-dark !important
    &.minimum
        border-bottom: 1px solid rgba(0,0,0,0.125)
        border-top: 1px solid rgba(0,0,0,0.125)
        margin: 20px 0
        padding: 5px 0
        .score-team-card
            margin-bottom: 0px
            .score-team
                font-size: 0.9rem !important
    .vs
        display: none
        font-weight: bold
        font-size: 0.8rem
    .score-team-card
        display: flex
        flex-direction: row
        flex-grow: 1
        align-items: center
        .score-team
            white-space: nowrap
            text-overflow: ellipsis
            overflow: hidden
            flex-grow: 1 
            font-size: 1rem;
            font-weight: 900;
            order: 1
            text-align: left
        .score-numbers
            flex-shrink: 0
            font-size: 1.2rem
            text-align: right
            font-weight: bold
            display: inline-block
            height: 40px
            width: 40px
            line-height: 40px
            order: 2
            .no-result
                background-color: transparent
                border: 0 
                color: #191919
        .scores .score-team,
        .scores .score-numbers
            font-weight: bold
            color: #191919
            letter-spacing: -0.08rem
            font-size: 1.4rem


@media (min-width: 768px)
    .scores
        flex-direction: row
        justify-content: space-between
        align-items: center
        .vs
            display: block
        .score-team-card
            max-width: 48% !important
            .score-team
                text-align: left
                order: 1
            .score-numbers
                font-size: 1.6rem
                text-align: center
                order: 2
            &:last-child
                .score-team
                    order: 2
                    text-align: right
                .score-numbers
                    order: 1
        &.minimum
            .score-team
                padding: 0.325rem