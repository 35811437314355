/*********************************************************************************************************************************************/
/* CIERRE & FOOTER */

#feedback
  .hidden
    text-align: center
    textarea
      height: 80px
      resize: none


footer
    #footer-links
        width: 100%
        max-width: 1000px
    flex-wrap: wrap
    padding: 2em
    background: white
    color: #191919
    display: flex
    flex-direction: column
    justify-content: space-around
    ul
        display: flex
        flex-direction: column
        list-style: none
        padding: 0
        align-items: start;
        border-bottom: 1px solid $color-grey-1
        padding: 1rem 0
        li
            margin-bottom: 10px
            display: block
            span
                color: $color-text-muted
                font-weight: 400
                letter-spacing: -0.05rem
            a
                font-size: 14px
                &:not(active)
                &:hover
                &:visited
                    color: $color-text-link !important
    .follow-us
        a
            i
                color: $color-dark
            &:hover
                i
                    color: $color-dark-light
            &:active
                i
                    color: $color-dark-dark


@media screen and (min-width: 768px)
    footer
        flex-direction: column
        justify-content: unset
        align-items: center
        li
            display: inline-block
        li
            +number(1)
                margin-left: 0
        div
            a
                font-size: 1rem
            li
                margin-bottom: 1rem
        ul
            flex-direction: row
            flex-wrap: wrap
            li
                flex-basis: 33.333333333333336% !important;
                &:first-child
                    flex-basis: 100% !important
        &.logged-admin
            margin-left: 220px;

  


