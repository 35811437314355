#hero
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  position: relative
  padding: 0
  z-index: 0
  min-height: 600px
  .hero-wrapper
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 1.5rem 1.5rem 2.5rem
    color: white
    .isologo
      max-width: 300px
      margin: 1rem auto
      display: block
      flex-grow: 0
      img
        width: 100%
    h1
      margin: 0 0 1.5rem 0
      font-weight: 900
      text-align: center
      max-width: 800px
    p
      font-size: 1.3rem
      line-height: 130%
      font-weight: bold
      text-align: center
      min-width:100% // width: 80vw
      max-width: 700px
    .hero-info
      text-align: center
    .hero-video
      width: 100%
      div
        position: relative
        width: 100%
        padding-top: 56.25% // 16:9 Aspect Ratio
        box-shadow: 0 55px 40px -20px rgba(0, 0, 0, 0.6)
        iframe
          position: absolute
          display: block
          border: 4px solid #b2b4b6;
          overflow: hidden;
          border-radius: 0.5rem;
          top: 0
          left:0
          width: 100%
          height: 100%
          object-fit: cover

#hero
    .hero-landing
        min-height: 100%

a.landing-cta
	padding: 15px 25px
	font-weight: 900
	text-transform: uppercase
	letter-spacing: 0.5px
	background-color: white
	color: $color-dark
	&:hover
		color: white
		background-color: $color-dark

.don-slogan-cta
	text-align: center
	padding: 3rem 1rem
	border-top: 5px solid #4aa354
	border-bottom: 5px solid #4aa354
	h2
		font-size: 2.3rem
		font-weight: 900
		line-height: 100%
		letter-spacing: -0.1rem
		margin-bottom: 2rem
	img
		width: 20%
		max-width: 60px
	h2
	a
		margin-bottom: 20px

.flags
  background: $color-dark-light
  span
    color: white
    font-weight: bolder
  img
    max-width: 20px
    &.flag-no
      opacity: 0.15

.box-features
  margin-top: 4rem
	#banner-icons
		background-image: url(../img/icon-illustrations/banner-icons.png)
		height: 30px
		background-repeat: repeat-x
		overflow: hidden
		display: block
		background-size: auto 30px
		margin: 40px 0
		opacity: 0.2
  .row
    h2
      margin: 1rem 1rem 2rem 1rem
      font-weight: 900
      font-size: 2.25rem
      line-height: 90%
      text-align: center
      margin-bottom: 4rem
      position: relative
	.feature
		flex-direction: column
		display: flex
		align-items: center
		justify-content: flex-start
		max-width: 1000px
		padding-bottom: 1.5rem
		margin-bottom: 1.5rem
		div
			max-width: 500px;
			order: 1
			h2
				margin: 1.5rem 0.5rem 1rem
				font-size: 1.9rem
				font-weight: 900
				letter-spacing: -1px
				position: relative
				letter-spacing: -2px
				max-width: 500px
				line-height: 110%
			p
				font-size: 1.1rem
				color: #575757 !important
				letter-spacing: -0.75px
				line-height: 1.5
			span
				margin: 0 0.5rem
		img
			order: 2
			max-width: 500px
			width: 100%



#btn-landing-complejos
	position: relative
	border-radius: 1rem
	margin: 1rem 1rem 2rem
	overflow: hidden
	h3, i
		color: white
		text-shadow: 0 5px 10px rgba(0, 0, 0, 0.5)

.landing-card
	border-radius: 1rem
	overflow: hidden
	padding: 1.5rem
	background-color: white
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.18)
	i
		color: $color-primary


/*****************************************************************************************/
/*** MEDIA QUERIES **********************************************************************/
/*****************************************************************************************/

@media (min-width: 575px)
	.don-slogan-cta
		h2
			font-size: 1.6rem
	.box-features
		.row
			h2
				font-size: 2.3rem
				margin: 1rem auto 2rem auto
		.feature
			h2
				font-size: 2.1rem

@media (min-width: 992px)
	.don-slogan-cta h2
		font-size: 2.3rem
		max-width: 600px
		margin-left: auto
		margin-right: auto
	#hero
    &.hero-landing
      .hero-wrapper
        flex-direction: row
        max-width: 1200px;
        width: 100%;
        .hero-info
        .hero-video
          width: 50%
          max-width: 700px
        .hero-info
          .isologo
            margin: 1rem 0
		a.landing-link.landing-cta
			margin-top: 20px
		.bg-cancha
			background-position: center 20%
	.feature
		margin: 0 auto 2.5rem auto
		div
			text-align: left
			flex-grow: 1
			h2
				text-align: left !important
				font-size: 2.3rem
				margin: 0 0 1rem 0
		img
			max-width: 500px !important
		.row
			h2
				font-size: 2.7rem