@font-face {
  font-family: 'icon-don';
  src: url("../fonts/icon-don.eot?9oloq1");
  src: url("../fonts/icon-don.eot?9oloq1#iefix") format('embedded-opentype'), url("../fonts/icon-don.ttf?9oloq1") format('truetype'), url("../fonts/icon-don.woff?9oloq1") format('woff'), url("../fonts/icon-don.svg?9oloq1#icon-don") format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i.icon-don {
  font-family: 'icon-don' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;
  color: #858e96;
  display: inline-block;
  vertical-align: middle;
/* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
i.icon-don.icon-sm {
  font-size: 16px !important;
}
i.icon-don.icon-md {
  font-size: 24px !important;
}
i.icon-don.icon-lg {
  font-size: 32px !important;
}
i.icon-don.icon-xl {
  font-size: 48px !important;
}
.icon-about:before {
  content: "\e900";
}
.icon-action-add:before {
  content: "\e901";
}
.icon-action-close:before {
  content: "\e904";
}
.icon-action-confirm:before {
  content: "\e906";
}
.icon-action-delete:before {
  content: "\e903";
}
.icon-action-edit:before {
  content: "\e905";
}
.icon-action-more:before {
  content: "\e953";
}
.icon-action-remove:before {
  content: "\e907";
}
.icon-arrow-chevron-down:before {
  content: "\e908";
}
.icon-arrow-chevron-left:before {
  content: "\e909";
}
.icon-arrow-chevron-right:before {
  content: "\e90a";
}
.icon-arrow-chevron-up:before {
  content: "\e90b";
}
.icon-arrow-point-left:before {
  content: "\e90c";
}
.icon-arrow-point-down:before {
  content: "\e913";
}
.icon-arrow-point-right:before {
  content: "\e922";
}
.icon-arrow-point-up:before {
  content: "\e937";
}
.icon-calendar:before {
  content: "\e90d";
}
.icon-calendar-ok:before {
  content: "\e90e";
}
.icon-calendar-x:before {
  content: "\e90f";
}
.icon-challenges:before {
  content: "\e954";
}
.icon-comments:before {
  content: "\e910";
}
.icon-community:before {
  content: "\e911";
}
.icon-dashboard:before {
  content: "\e912";
}
.icon-dropdown:before {
  content: "\e94a";
}
.icon-enter:before {
  content: "\e914";
}
.icon-exit:before {
  content: "\e915";
}
.icon-faqs:before {
  content: "\e916";
}
.icon-favourite-off:before {
  content: "\e94b";
}
.icon-favourite-on:before {
  content: "\e94e";
}
.icon-field-position:before {
  content: "\e918";
}
.icon-fields:before {
  content: "\e919";
}
.icon-filters:before {
  content: "\e91a";
}
.icon-football-type:before {
  content: "\e91b";
}
.icon-friends:before {
  content: "\e91c";
}
.icon-games:before {
  content: "\e91d";
}
.icon-gender:before {
  content: "\e91e";
}
.icon-gender-femenine:before {
  content: "\e91f";
}
.icon-gender-masculine:before {
  content: "\e920";
}
.icon-help:before {
  content: "\e921";
}
.icon-info:before {
  content: "\e923";
}
.icon-landing:before {
  content: "\e924";
}
.icon-light:before {
  content: "\e925";
}
.icon-link:before {
  content: "\e926";
}
.icon-list:before {
  content: "\e927";
}
.icon-load-download:before {
  content: "\e956";
}
.icon-load-upload:before {
  content: "\e957";
}
.icon-location:before {
  content: "\e928";
}
.icon-lock-off:before {
  content: "\e958";
}
.icon-lock-on:before {
  content: "\e959";
}
.icon-mail:before {
  content: "\e92a";
}
.icon-menu:before {
  content: "\e92b";
}
.icon-money:before {
  content: "\e92c";
}
.icon-notification:before {
  content: "\e95a";
}
.icon-personality:before {
  content: "\e92d";
}
.icon-phone:before {
  content: "\e92e";
}
.icon-posts:before {
  content: "\e92f";
}
.icon-privacy:before {
  content: "\e930";
}
.icon-ratings:before {
  content: "\e931";
}
.icon-reports:before {
  content: "\e932";
}
.icon-reservation:before {
  content: "\e933";
}
.icon-reservations-log:before {
  content: "\e934";
}
.icon-roof:before {
  content: "\e935";
}
.icon-search:before {
  content: "\e936";
}
.icon-settings:before {
  content: "\e955";
}
.icon-share:before {
  content: "\e95b";
}
.icon-skill:before {
  content: "\e938";
}
.icon-social-facebook:before {
  content: "\e939";
}
.icon-social-instagram:before {
  content: "\e93a";
}
.icon-social-twitter:before {
  content: "\e93b";
}
.icon-social-whatsapp:before {
  content: "\e93c";
}
.icon-social-youtube:before {
  content: "\e93d";
}
.icon-sort-asc:before {
  content: "\e93e";
}
.icon-sort-desc:before {
  content: "\e93f";
}
.icon-status-error:before {
  content: "\e940";
}
.icon-status-info:before {
  content: "\e941";
}
.icon-status-success:before {
  content: "\e942";
}
.icon-status-warning:before {
  content: "\e943";
}
.icon-store:before {
  content: "\e944";
}
.icon-surface:before {
  content: "\e945";
}
.icon-teams:before {
  content: "\e946";
}
.icon-terms:before {
  content: "\e947";
}
.icon-time:before {
  content: "\e948";
}
.icon-unknown-player:before {
  content: "\e949";
}
.icon-user:before {
  content: "\e94c";
}
.icon-user-admin:before {
  content: "\e94d";
}
.icon-user-no-team:before {
  content: "\e902";
}
.icon-user-ok:before {
  content: "\e94f";
}
.icon-venue:before {
  content: "\e950";
}
.icon-visibility-off:before {
  content: "\e95c";
}
.icon-visibility-on:before {
  content: "\e95d";
}
.icon-window-maximize:before {
  content: "\e951";
}
.icon-window-minimize:before {
  content: "\e952";
}
html {
  background: #fafafa !important;
  scroll-behavior: smooth;
}
html,
body {
  color: #111;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: -0.03rem;
  height: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased /* Chrome, Safari */;
  -moz-osx-font-smoothing: grayscale /* Firefox */;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  background: unset !important;
  box-sizing: border-box;
}
::selection {
  color: #333;
  background: #41a160;
}
.login-actions {
  width: 250px;
}
.login-actions a.btn {
  width: 100%;
}
.login-actions a.social {
  position: relative;
  display: flex;
  align-items: center;
}
.login-actions a.social img {
  height: 24px;
  width: 24px;
  margin-right: 0.5rem;
}
.login-actions a:not(:last-child) {
  margin-bottom: 1rem;
}
.auth-don input:not(:last-child) {
  margin-bottom: 1rem;
}
.auth-don button {
  width: 100%;
}
#block {
  padding: 2rem 0 0;
}
#block .wrapper-login {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#block .wrapper-login #brand {
  display: block;
  margin: 0 auto 20px auto;
  max-width: 200px;
  width: 100%;
}
#block .wrapper-login .promo-login {
  max-width: 90%;
  margin: 0 auto auto;
}
#block .wrapper-login .login-banner {
  padding: 1.5rem 1.5rem 0;
  border-radius: 1rem;
  background: #fff;
  max-width: 500px;
}
#block .wrapper-login h1 {
  text-align: center;
}
#block .wrapper-login .access-login {
  margin: 1.5rem;
  width: 80%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 1rem;
}
@media (min-width: 768px) {
  #block {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    padding: unset;
  }
  #block .wrapper-login {
    flex-direction: row;
  }
  #block .wrapper-login .promo-login {
    margin-bottom: unset;
    padding: 2rem;
    width: 60vw;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  #block .wrapper-login .promo-login .login-banner {
    padding: 1.5rem;
  }
  #block .wrapper-login .promo-login h1 {
    max-width: 60%;
  }
  #block .wrapper-login .access-login {
    width: 40vw;
    margin: 0;
    padding: 3rem;
    height: 100%;
    border: unset;
    border-radius: unset;
    border-left: 1px solid #dee2e6;
  }
}
@media (min-width: 1200px) {
  .wrapper-login .promo-login {
    width: 70vw !important;
  }
  .wrapper-login .access-login {
    width: 30vw !important;
  }
}
nav.navbar {
  position: fixed;
  z-index: 1030;
  background: #fff;
  display: block;
  padding: 0;
  height: 60px;
  border-top: 1px solid #dee2e6;
  right: 0;
  left: 0;
  bottom: 0;
}
nav.navbar .avatar-don {
  margin-right: 0;
}
nav.navbar .container {
  display: block !important;
  height: 100%;
}
nav.navbar .container ul {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  height: 60px;
  justify-content: space-between;
}
nav.navbar .container ul li {
  list-style: none;
  height: 100%;
  vertical-align: middle;
}
nav.navbar .container ul li.brand-title {
  flex-grow: 1 !important;
}
nav.navbar .container ul li.brand-title img {
  height: 32px !important;
}
nav.navbar .container ul li a {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
nav.navbar .container ul li a:hover,
nav.navbar .container ul li a:active {
  text-decoration: none;
}
nav.navbar .container ul li a.nav-link {
  padding: 0.35rem;
}
nav.navbar .container ul li a.nav-link i,
nav.navbar .container ul li a.nav-link span {
  color: #111;
}
nav.navbar .container ul li a.nav-link i {
  font-size: 32px;
}
nav.navbar .container ul li a.nav-link span {
  font-weight: bold;
  font-weight: 900;
  font-size: 0.6rem;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  margin-top: 0.25rem;
  display: none;
}
nav.navbar .container ul li a.nav-link .icon-user-admin {
  color: #2e8149 !important;
  background: #fafafa;
}
nav.navbar.logged,
nav.navbar.admin-venue {
  border-top: 1px solid #c3ccd3;
}
nav.navbar.logged li.brand-title,
nav.navbar.admin-venue li.brand-title {
  display: none;
}
nav.navbar.logged li:not(.brand-title),
nav.navbar.admin-venue li:not(.brand-title) {
  text-align: center;
}
nav.navbar.guest {
  top: 0;
  bottom: unset !important;
}
nav.navbar.guest {
  border-bottom: 1px solid #dee2e6;
}
nav.navbar.backend {
  background-color: #dee2e6;
  border-color: #c3ccd3;
}
nav.navbar.admin-venue {
  background-color: #91b971;
}
nav.navbar.admin-venue li.active {
  background-color: #719653;
}
nav.navbar.admin-venue li.active i,
nav.navbar.admin-venue li.active span {
  color: #fff !important;
}
nav.navbar.logged li.active {
  border-top: 2px solid #2e8149 !important;
}
nav.navbar.logged li.active i,
nav.navbar.logged li.active span {
  color: #1c5a30 !important;
}
nav.navbar.guest {
  border-top: unset !important;
}
nav.navbar.guest li i,
nav.navbar.guest li span {
  color: #1c5a30 !important;
}
@media (min-width: 600px) {
  a.nav-link span {
    display: unset !important;
  }
}
@media (min-width: 768px) {
  nav.navbar li.brand-title {
    display: block !important;
  }
  nav.navbar.navbar,
  nav.navbar:not(.admin-venue) {
    top: 0 !important;
    bottom: unset;
    border-top: unset !important;
    border-bottom: 1px solid #dee2e6;
    height: 60px;
  }
  nav.navbar:not(.admin-venue) li.active {
    border-bottom: 2px solid #2e8149 !important;
    border-top: 0 !important;
  }
  nav.navbar.admin-venue {
    border-bottom: 0;
    bottom: 0;
    left: 0;
    right: unset;
    height: 100%;
    width: 220px;
    border-right: 1px solid #c3ccd3;
  }
  nav.navbar.admin-venue .container {
    padding: 0;
  }
  nav.navbar.admin-venue .container ul {
    display: flex !important;
    flex-direction: column;
  }
  nav.navbar.admin-venue .container ul li:first-child {
    flex-grow: unset !important;
    display: flex !important;
    justify-content: space-between;
    border-bottom: 1px solid #719653;
    padding: 0.75rem 0;
  }
  nav.navbar.admin-venue .container ul li:first-child a {
    width: unset !important;
  }
  nav.navbar.admin-venue .container ul li a {
    width: 100%;
    text-align: left !important;
    height: unset;
  }
  nav.navbar.admin-venue .container ul li a.nav-link {
    flex-direction: row;
    padding: 0.5rem 1rem !important;
    height: unset;
    justify-content: start;
    width: 100%;
  }
  nav.navbar.admin-venue .container ul li a.nav-link i,
  nav.navbar.admin-venue .container ul li a.nav-link span {
    color: #222;
  }
  nav.navbar.admin-venue .container ul li a.nav-link span {
    font-size: 0.7rem !important;
  }
  nav.navbar.admin-venue .container ul li a.nav-link i {
    margin-right: 0.25rem;
  }
  nav.navbar.admin-venue .container ul li.active {
    background-color: #719653;
  }
  nav.navbar.admin-venue .container ul li.active i,
  nav.navbar.admin-venue .container ul li.active span {
    color: #fff !important;
  }
}
.noScroll {
  overflow: hidden;
}
#sidebar-drawer {
  -webkit-overflow-scrolling: touch;
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
#sidebar-drawer .bg {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: -1;
}
#sidebar-drawer a:hover,
#sidebar-drawer a:active {
  text-decoration: none;
}
#sidebar-drawer .drawer-content {
  overflow: auto;
  position: relative;
  width: 90%;
  max-width: 600px;
  height: auto;
  max-height: 100%;
  background-color: #fff;
}
#sidebar-drawer .drawer-content .drawer-header {
  width: 100%;
  height: 70px;
  top: 0;
  z-index: 1029;
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#sidebar-drawer .drawer-content .drawer-header img.logo {
  width: 200px;
}
#sidebar-drawer .drawer-content .drawer-body {
  overflow: hidden;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  max-height: calc(100% - 70px);
  width: 100%;
}
#sidebar-drawer .drawer-content .drawer-body .row {
  padding: 10px;
}
#sidebar-drawer .drawer-content .drawer-body .drawer-item {
  margin-bottom: 1rem;
  box-shadow: 0 1px 2px 1px rgba(0,0,0,0.1);
  border-radius: 0.35rem;
}
#sidebar-drawer .drawer-content .drawer-body .drawer-item a {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px 10px 10px;
}
#sidebar-drawer .drawer-content .drawer-body .drawer-item a span:not(.hint) {
  font-size: 0.8rem;
  font-weight: 700;
  color: #222;
  display: flex;
  align-items: center;
}
#sidebar-drawer .drawer-content .drawer-body .drawer-item a span:not(.hint) .icon-don {
  color: #41a160;
}
#sidebar-drawer .drawer-content .drawer-body .drawer-item a span:not(.hint) .icon-don,
#sidebar-drawer .drawer-content .drawer-body .drawer-item a span:not(.hint) img {
  margin-right: 5px;
}
#sidebar-drawer .drawer-content .drawer-body .drawer-item a span.hint {
  margin-top: 5px;
  display: none;
  font-size: 0.8rem;
  color: #858e96;
}
#sidebar-drawer .drawer-content .drawer-body .drawer-item.active {
  background: #dee2e6;
  opacity: 0.5;
}
#sidebar-drawer .drawer-content .drawer-body .drawer-item.admin-access i {
  color: #2e8149 !important;
}
#sidebar-drawer .drawer-content .drawer-body .drawer-item.highlighted a span {
  font-weight: bolder;
  font-size: 1.2rem;
}
#sidebar-drawer .drawer-content .drawer-body .drawer-item.highlighted a span.hint {
  font-size: 0.8rem;
  font-weight: 700;
}
@media (min-width: 601px) {
  #sidebar-drawer .drawer-item {
    margin-bottom: 30px;
  }
  #sidebar-drawer .drawer-item span.hint {
    display: block !important;
  }
}
[aria-hidden='cookieconsent'] {
  font-family: "Lato", sans-serif;
  font-size: 0.9rem;
  position: fixed;
  bottom: 20px !important;
  max-width: calc(100% - 40px) !important;
  left: 20px !important;
  padding: 1rem !important;
  overflow: auto;
  border: 1px solid #8ec760;
  background-color: #fff !important;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}
@media only screen and (min-width: 400px) {
  [aria-hidden='cookieconsent'] {
    max-width: 360px !important;
  }
}
.cc-message {
  display: flex;
  flex-grow: 1 !important;
  margin-bottom: 1em;
}
.cc-floating .cc-compliance>.cc-btn {
  flex: unset !important;
  align-self: flex-end;
}
.cc-floating .cc-compliance {
  display: flex;
  justify-content: flex-end;
}
.cc-btn.cc-dismiss {
  background-color: #222 !important;
}
.fb_iframe_widget_fluid_desktop iframe {
  width: 100% !important;
}
main {
  background-color: #fafafa;
  padding-bottom: 50px;
  min-height: 100%;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}
main .container {
  margin-top: 20px;
}
main:not(.logged):not(.logged-admin) {
  padding-top: 60px;
}
@media (min-width: 768px) {
  .logged-admin {
    margin-left: 220px;
  }
}
.content {
  margin: 0 auto 70px auto;
  padding-top: 80px;
}
main #sticky-bar {
  position: sticky;
  position: -webkit-sticky;
  top: 59px;
  left: 0;
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  border-bottom: 1px solid #dee2e6;
  z-index: 1000;
}
main #sticky-bar .sticky-body {
  width: 100%;
  margin: auto;
}
main:not(.logged-admin) #sticky-bar {
  z-index: 2;
}
main.logged #sticky-bar,
main.logged-admin #sticky-bar {
  top: -1px;
}
@media (max-width: 576px) {
  .sticky-body {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  main #sticky-bar .sticky-body {
    max-width: 720px;
  }
  main.logged #sticky-bar,
  main.logged-admin #sticky-bar {
    top: 59px;
  }
  main.logged {
    padding-top: 60px;
  }
  main.logged-admin #sticky-bar {
    top: 0 !important;
    z-index: 3;
  }
  main.logged-admin #sticky-bar .sticky-body {
    max-width: unset !important;
  }
}
@media (min-width: 992px) {
  main #sticky-bar .sticky-body {
    max-width: 800px;
  }
}
#games-search-box {
  display: none;
}
#filter {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1031;
}
#filter .bg {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.9;
  z-index: -1;
}
#change-location-map {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  z-index: 1;
}
#change-location-map .alert {
  position: absolute;
  z-index: 2;
  top: -15px;
  left: 50%;
  min-width: 260px;
  transform: translateX(-50%);
}
#change-location-map #map {
  width: 100%;
  height: 100%;
}
.filter-content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 400px;
  max-height: 600px;
  background: #fff;
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid #c3ccd3;
}
#filter .filter-header,
#filter .filter-footer {
  position: absolute;
  height: 70px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  background-color: #fff;
}
#filter .filter-header {
  top: 0;
}
#filter .filter-footer {
  bottom: 0;
  justify-content: flex-end;
}
#filter .filter-body {
  overflow-y: auto;
  padding: 30px;
  display: block;
  width: 100%;
  margin: 70px 0;
  max-height: calc(90vh - 140px);
}
.modal-backdrop {
  background-color: #fff;
  opacity: 0.9;
}
#show-install-banner {
  display: none !important;
}
@font-face {
  font-family: 'trashhand';
  src: url("../fonts/TrashHand-webfont.eot");
  src: url("../fonts/TrashHand-webfont.eot?#iefix") format('embedded-opentype'), url("../fonts/TrashHand-webfont.woff") format('woff'), url("../fonts/TrashHand-webfont.ttf") format('truetype'), url("../fonts/TrashHand-webfont.svg#trashhandregular") format('svg');
  font-weight: normal;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
.font-1,
.font-2,
.font-3,
.font-4 {
  font-weight: 900;
}
h1.display,
.display {
  font-size: 2.488rem;
  line-height: 120%;
}
@media (max-width: 600px) {
  h1.display {
    font-size: 2.3rem;
  }
}
h1,
.font-1 {
  font-size: 2.074rem;
  letter-spacing: -0.12rem;
}
h2,
.font-2 {
  font-size: 1.728rem;
  letter-spacing: -0.07rem;
}
h3,
.font-3 {
  font-size: 1.44rem;
  letter-spacing: -0.05rem;
}
h4,
.font-4 {
  font-size: 1.2rem;
  letter-spacing: -0.05rem;
}
h5,
.font-5 {
  font-size: 1rem;
  letter-spacing: -0.05rem;
}
h6,
.font-6 {
  font-size: 0.833rem;
  letter-spacing: -0.05rem;
}
@media (min-width: 600px) {
  h1 {
    text-align: center;
  }
  h1.display {
    font-size: 2.986rem;
  }
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #222 !important;
}
.trashhand {
  font-family: "trashhand", 'lato', sans-serif;
  font-weight: 400 !important;
  letter-spacing: -1px !important;
}
.font-weight-bolder {
  font-weight: 900;
}
.text-small,
small {
  font-size: 0.694rem;
  font-weight: 700;
}
.text-no-break {
  white-space: nowrap !important;
}
.text-break {
  white-space: normal !important;
}
.text-muted {
  color: #6c757d;
}
.text-white {
  color: #fff !important;
}
.text-black {
  color: #222 !important;
}
.text-primary {
  color: #2e8149 !important;
}
.text-secondary {
  color: #83ad62 !important;
}
.text-danger {
  color: #e74946 !important;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.proper-read {
  line-height: 1.5;
}
.proper-read.big {
  font-size: 1.1rem;
}
a,
button,
.btn {
  font-weight: 700;
}
a:not(.btn) {
  color: #2e8149;
}
a:hover,
a:active {
  color: #2e8149;
  text-decoration: none;
}
a:hover:not(.btn) {
  color: #41a160;
}
a:active:not(.btn),
a:visited:not(.btn) {
  color: #1c5a30;
}
.btn {
  white-space: normal;
  border: 0;
  padding: 0.5rem 0.75rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.btn.disabled {
  opacity: 0.25 !important;
  pointer-events: none;
}
.btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.3) !important;
}
.btn.btn-no-style {
  background-color: transparent !important;
}
.btn i {
  color: unset !important;
  margin-right: 0.25rem;
}
.btn.btn-icon {
  padding: 0.5rem;
}
.btn.btn-icon i {
  margin-right: 0;
}
.btn.btn-lg {
  line-height: 1.5rem;
  padding: 0.75rem 1rem;
}
.btn.btn-lg.btn-icon {
  padding: 0.5rem;
}
.btn.btn-lg.btn-icon i {
  font-size: 32px;
}
.btn.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.btn.btn-sm.btn-icon {
  padding: 0.25rem;
}
.btn.btn-sm i {
  margin-right: 0;
}
.btn.btn-full-width {
  width: 100%;
  margin-bottom: 1rem;
}
.btn.btn-full-height {
  height: 100%;
}
.btn.btn-full-width,
.btn.btn-full-height {
  display: flex;
}
.btn.btn-wrap {
  white-space: normal;
}
.btn.btn-primary {
  color: #fff !important;
  background-color: #2e8149;
}
.btn.btn-primary:hover,
.btn.btn-primary:active {
  background-color: #41a160 !important;
}
.btn.btn-secondary {
  color: #fff;
  background-color: #719653;
}
.btn.btn-secondary:hover,
.btn.btn-secondary:active {
  background-color: #83ad62 !important;
}
.btn.btn-info {
  background-color: #0076b4;
  color: #fff !important;
}
.btn.btn-info i {
  color: #fff !important;
}
.btn.btn-info:hover,
.btn.btn-info:active {
  background-color: #0d94da;
}
.btn.btn-warning {
  background-color: #ffc107;
  color: #222 !important;
}
.btn.btn-warning i {
  color: #222 !important;
}
.btn.btn-warning:hover,
.btn.btn-warning:active {
  background-color: #ffcd38;
}
.btn.btn-danger {
  background-color: #e74946;
}
.btn.btn-danger:hover,
.btn.btn-danger:active {
  background-color: #f65a57;
}
.btn.btn-dark {
  background-color: #222;
  color: #fff !important;
}
.btn.btn-dark i {
  color: #fff !important;
}
.btn.btn-dark:hover,
.btn.btn-dark:active {
  background-color: #333;
}
.btn.btn-link {
  box-shadow: unset;
  background-color: transparent;
  text-decoration: none !important;
}
.btn.btn-link:not(.text-danger),
.btn.btn-link:not(.text-black) {
  color: #2e8149;
}
.btn.btn-link:not(.text-danger) i,
.btn.btn-link:not(.text-black) i {
  color: #2e8149;
}
.btn.btn-link:hover,
.btn.btn-link:active {
  background-color: rgba(17,45,26,0.15) !important;
}
.btn.btn-light {
  background: #fff;
  border: 1px solid #9ca5ae;
}
.btn.btn-light:hover,
.btn.btn-light:active {
  background-color: #dee2e6;
  border-color: #6c757d;
}
.dropdown-item:hover,
.dropdown-item:active {
  background-color: rgba(17,45,26,0.15) !important;
}
ul.menu-slide {
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  justify-content: start;
  display: flex;
  width: 100%;
  padding: 0px;
  margin: 0px 0px -1px !important;
  border-bottom: 0px !important;
  -webkit-overflow-scrolling: touch;
}
ul.menu-slide li {
  display: inline-flex !important;
  margin-right: -4px !important;
  padding: 0;
  border-bottom: 1px solid #dee2e6;
  align-items: center;
}
ul.menu-slide li a {
  text-transform: uppercase;
  color: #2e8149 !important;
  font-size: 0.833rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  text-decoration: none;
  font-weight: 700;
}
ul.menu-slide li a i {
  line-height: 1rem !important;
  color: #2e8149 !important;
}
ul.menu-slide li:not(.active) a:hover {
  background-color: rgba(46,129,73,0.1);
}
ul.menu-slide li.disabled span {
  text-transform: uppercase;
  color: #c3ccd3 !important;
  font-size: 0.833rem;
  padding: 0.5rem 0.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  font-weight: 700;
}
ul.menu-slide li:last-child {
  margin-right: 0 !important;
}
ul.menu-slide li.active {
  border-bottom: 1px solid #222 !important;
}
ul.menu-slide li.active > a {
  font-weight: 900;
  color: #111 !important;
}
@media (min-width: 600px) {
  .menu-slide {
    justify-content: center !important;
    white-space: normal !important;
    overflow-x: hidden !important;
  }
}
.bg-hero {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center top;
  background-color: rgba(0,0,0,0.25);
  background-blend-mode: multiply;
}
.bg-cancha {
  background-image: url("../img/bg-cancha.svg");
}
.bg-landing {
  background-image: url("../img/home-landing/jugafutbolcomonunca_2_mobile.jpg");
  background-position: center center;
}
.bg-landing-jugapartido {
  background-image: url("../img/home-landing/jugaunpartido_2_mobile.jpg");
  background-position: center 60%;
}
.bg-landing-creapartido {
  background-image: url("../img/home-landing/organizaunpartido_2_mobile.jpg");
  background-position: center center;
}
.bg-landing-equipos {
  background-image: url("../img/home-landing/jugaenequipos_2_mobile.jpg");
  background-position: center center;
}
.bg-venues {
  background-color: rgba(0,0,0,0.45);
  background-image: url("../img/home-landing/canchasycomplejos_2_mobile.jpg");
  background-position: center center;
}
.bg-events {
  background-image: url("../img/home-landing/eventoscorporativos_2_mobile.jpg");
  background-position: center bottom;
}
@media (min-width: 575px) {
  .bg-landing {
    background-image: url("../img/home-landing/jugafutbolcomonunca_1_tablet.jpg");
  }
  .bg-landing-jugapartido {
    background-image: url("../img/home-landing/jugaunpartido_1_tablet.jpg");
  }
  .bg-landing-creapartido {
    background-image: url("../img/home-landing/organizaunpartido_1_tablet.jpg");
  }
  .bg-landing-equipos {
    background-image: url("../img/home-landing/jugaenequipos_1_tablet.jpg");
  }
  .bg-venues {
    background-image: url("../img/home-landing/canchasycomplejos_1_tablet.jpg");
  }
  .bg-events {
    background-image: url("../img/home-landing/eventoscorporativos_1_tablet.jpg");
  }
}
@media (min-width: 992px) {
  .bg-landing {
    background-image: url("../img/home-landing/jugafutbolcomonunca.jpg");
  }
  .bg-landing-jugapartido {
    background-image: url("../img/home-landing/jugaunpartido.jpg");
  }
  .bg-landing-creapartido {
    background-image: url("../img/home-landing/organizaunpartido.jpg");
  }
  .bg-landing-equipos {
    background-image: url("../img/home-landing/jugaenequipos.jpg");
  }
  .bg-venues {
    background-image: url("../img/home-landing/canchasycomplejos.jpg");
  }
  .bg-events {
    background-image: url("../img/home-landing/eventoscorporativos.jpg");
  }
}
.bg-pattern-abstract {
  background-image: url("../img/patterns/abstract.svg");
  background-color: #fff;
  background-repeat: repeat;
  background-position: center 0;
  background-size: cover;
}
.bg-pattern-abstract.corporate {
  background-image: url("../img/patterns/abstract-corporate.svg");
}
.bg-pattern-futbol {
  background-image: url("../img/patterns/pattern-don-final.gif");
  background-color: #fff;
  background-repeat: repeat;
  background-position: center center;
  background-size: 250px;
  background-image: url("../img/patterns/pattern-don-final.gif");
}
.bg-pattern-futbol.bg-alternative {
  background-blend-mode: multiply;
  background-color: #83ad62;
}
.bg-black {
  background-color: #222;
}
.bg-primary {
  background-color: #2e8149 !important;
}
.bg-secondary {
  background-color: #83ad62 !important;
}
.border-primary {
  border-color: #2e8149 !important;
}
.border-secondary {
  border-color: #83ad62 !important;
}
.admin {
  margin-right: 0.2rem;
}
.admin i.icon-user-admin {
  color: #e7ae02;
  background-color: #fff;
  border-radius: 50%;
  font-size: 20px;
  border: 1px solid #c3ccd3;
}
.admin img {
  width: 24px !important;
  height: 24px !important;
}
img.icon-sm {
  height: 16px !important;
}
img.icon-md {
  height: 24px !important;
}
img.icon-lg {
  height: 32px !important;
}
img.icon-xl {
  height: 48px !important;
}
.w-100 {
  width: 100% !important;
}
.relative {
  position: relative;
}
.hidden {
  display: none;
}
.flex-grow-1 {
  flex-grow: 1;
}
.d-two-columns {
  column-count: 2;
  gap: 1rem;
}
.gap-1 {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}
.clickable-card {
  cursor: pointer;
}
.isDisabled {
  pointer-events: none;
  opacity: 0.3;
}
.shadow-none {
  box-shadow: 0 0 !important;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.175) !important;
}
.opacity-0 {
  opacity: 0;
}
.opacity-1 {
  opacity: 0.2;
}
.opacity-2 {
  opacity: 0.4;
}
.opacity-3 {
  opacity: 0.6;
}
.opacity-4 {
  opacity: 0.8;
}
.opacity-5 {
  opacity: 1;
}
@media (min-width: 768px) {
  .scroll-with-mt {
    scroll-margin-top: 76px;
  }
}
.list-group-item.active,
.list-group-item:active {
  background: #2e8149;
  border-color: #2e8149;
  color: #fff !important;
}
.dropdown-menu {
  min-width: 275px !important;
}
.dropdown-menu a {
  justify-content: left;
}
.progreso-label .block {
  display: inline-block;
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.25rem;
}
.progreso-label .block.value-empty {
  background-color: #dee2e6;
}
.progreso-label .block.value-0 {
  background-color: #2e8149;
}
.progreso-label .block.value-25 {
  background-color: #83ad62;
}
.progreso-label .block.value-50 {
  background-color: #e7ae02;
}
.progreso-label .block.value-75 {
  background-color: #e74946;
}
.progreso-label .block.value-100 {
  background-color: #333;
}
.progreso {
  width: 100%;
  background: #dee2e6;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  display: block;
  overflow: hidden;
}
.progreso .contenedor-barra {
  display: block;
  height: 4px;
}
.progreso span.progreso-barra {
  display: block;
  height: 4px;
  width: auto;
}
.progreso span.progreso-barra.value-0 {
  background-color: #2e8149;
}
.progreso span.progreso-barra.value-25 {
  background-color: #83ad62;
}
.progreso span.progreso-barra.value-50 {
  background-color: #e7ae02;
}
.progreso span.progreso-barra.value-75 {
  background-color: #e74946;
}
.progreso span.progreso-barra.value-100 {
  background-color: #333;
}
.progreso span.progreso-barra.animated {
  width: 0;
  animation-name: showProgress;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}
code {
  display: block;
}
div.codeBlock,
div:not(.codeBlock) > code {
  border: 1px solid #c3ccd3;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
}
.close-x i {
  color: rgba(0,0,0,0.45);
  font-size: 32px;
}
.copyClipboard {
  position: relative;
}
.copyClipboard small {
  position: absolute !important;
  display: none;
  left: 50% !important;
  top: -10px !important;
  transform: translateX(-50%) !important;
}
.copyClipboard span {
  font-size: 0.6rem;
  font-weight: bold;
  text-transform: uppercase;
}
a.ayuda {
  width: 22px !important;
  height: 22px !important;
  border-radius: 50%;
  padding: 2px;
  outline: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
i.ayuda {
  color: #2e8149;
}
.breadcrumb {
  font-size: 0.8rem;
  background-color: transparent;
  margin-bottom: 1rem;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: nowrap;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6 !important;
}
.modal-body {
  text-align: center;
}
#install-app-banner .modal-footer {
  border: 0;
  background-color: #83ad62;
}
@media (max-width: 575px) {
  .modal-dialog {
    margin: 1rem;
  }
}
.card-outline {
  background: transparent;
  border: 2px dashed #dee2e6;
  border-radius: 0.25rem;
  aspect-ratio: 2/1;
  width: 100%;
  margin-bottom: 20px;
}
.card,
.modal-dialog {
  box-shadow: 0 0 15px rgba(0,0,0,0.15);
}
.card h1,
.modal-dialog h1,
.card h2,
.modal-dialog h2,
.card h3,
.modal-dialog h3,
.card h4,
.modal-dialog h4,
.card h5,
.modal-dialog h5,
.card h6,
.modal-dialog h6 {
  color: #222;
}
.card h2,
.modal-dialog h2 {
  font-size: 1.2rem;
}
.card .card-header,
.modal-dialog .card-header,
.card .modal-header,
.modal-dialog .modal-header {
  background-color: #fff;
}
.card .card-header .card-title,
.modal-dialog .card-header .card-title,
.card .modal-header .card-title,
.modal-dialog .modal-header .card-title,
.card .card-header .modal-title,
.modal-dialog .card-header .modal-title,
.card .modal-header .modal-title,
.modal-dialog .modal-header .modal-title {
  margin-bottom: 0;
}
.card .card-header h6,
.modal-dialog .card-header h6,
.card .modal-header h6,
.modal-dialog .modal-header h6 {
  margin-bottom: 0;
  font-size: 0.7em;
  text-transform: uppercase;
  color: #6c757d;
}
.card .card-footer,
.modal-dialog .card-footer {
  background-color: unset;
}
ul.numbers {
  padding: 0;
  margin: 20px 0;
  counter-reset: step;
}
ul.numbers li {
  list-style-type: none;
  font-size: 1em;
  position: relative;
  display: block;
  text-align: left;
  margin-bottom: 1rem;
  padding-left: 2rem;
}
ul.numbers li:before {
  content: counter(step);
  counter-increment: step;
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.85em;
  font-weight: bold;
  border-radius: 50%;
  border: 1.5px solid;
  margin: 0 15px 0 0;
  background: #41a160;
  color: #fff;
  position: absolute;
  left: 0;
}
ul.pagination {
  margin-top: 2rem;
  width: 100%;
  justify-content: center;
}
ul.pagination li {
  margin: 0 0.125rem;
}
ul.pagination li,
ul.pagination li a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.8rem;
}
ul.pagination li a {
  background: #2e8149;
  color: #fff !important;
  border-radius: 0.25rem;
}
.table.borderless tr th,
.table.borderless tr td {
  border: none;
}
.table-centered tr th,
.table-centered tr td {
  vertical-align: middle;
  text-align: center;
}
.badge {
  font-size: 0.694rem;
  text-transform: uppercase;
  background: #fff;
}
.badge.badge-primary {
  background: #85c299;
  color: #111;
  border: 1px solid #2e8149;
}
.badge.badge-primary i {
  color: #111;
}
.badge.badge-secondary {
  background: #b5d59d;
  color: #111;
  border: 1px solid #83ad62;
}
.badge.badge-secondary i {
  color: #111;
}
.badge.badge-success {
  background: #d4edda;
  color: #155724;
  border: 1px solid #6dbc7f;
}
.badge.badge-success i {
  color: #155724;
}
.badge.badge-info {
  background: #c1e7fa;
  color: #0c5460;
  border: 1px solid #3fade7;
}
.badge.badge-info i {
  color: #0c5460;
}
.badge.badge-warning {
  background: #f4e5b6;
  color: #856404;
  border: 1px solid #f5c538;
}
.badge.badge-warning i {
  color: #856404;
}
.badge.badge-danger {
  background: #f4cac9;
  color: #721c24;
  border: 1px solid #ee9190;
}
.badge.badge-danger i {
  color: #721c24;
}
.badge.badge-light {
  background: #fff;
  color: #626262;
  border: 1px solid #c3ccd3;
}
.badge.badge-light i {
  color: #626262;
}
.badge.badge-dark {
  background: #dbdbdb;
  color: #1b1e21;
  border: 1px solid #a2a2a2;
}
.badge.badge-dark i {
  color: #1b1e21;
}
.badge.pronto-arrow {
  font-family: 'lato', sans-serif;
  position: relative;
  color: #222;
  text-shadow: 0 1px rgba(255,255,255,0.2);
  background-color: transparent;
}
.badge.pronto-arrow::after {
  content: "";
  background-image: url("../img/icon-illustrations/arrow.svg");
  width: 17px;
  height: 21px;
  overflow: hidden;
  position: absolute;
  left: -19px;
  top: -7px;
  transform: rotate(82deg);
  z-index: 0;
}
.badge.pronto-arrow.pronto-right::after {
  right: -19px !important;
  left: unset;
  top: -7px;
  transform: rotate(-90deg) scaleX(-1);
}
.alert {
  padding: 0.75rem;
}
.alert.alert-primary {
  background: #85c299;
  color: #111 !important;
}
.alert.alert-primary > i {
  color: #111 !important;
}
.alert.alert-secondary {
  background: #b5d59d;
  color: #111 !important;
}
.alert.alert-secondary > i {
  color: #111 !important;
}
.alert.alert-success {
  background: #d4edda;
  border-color: #6dbc7f;
  color: #155724 !important;
}
.alert.alert-success > i {
  color: #155724 !important;
}
.alert.alert-info {
  background: #c1e7fa;
  border-color: #3fade7;
  color: #0c5460 !important;
}
.alert.alert-info > i {
  color: #0c5460 !important;
}
.alert.alert-warning {
  background: #f4e5b6;
  border-color: #f5c538;
  color: #856404 !important;
}
.alert.alert-warning > i {
  color: #856404 !important;
}
.alert.alert-danger {
  background: #f4cac9;
  border-color: #ee9190;
  color: #721c24 !important;
}
.alert.alert-danger > i {
  color: #721c24 !important;
}
.alert.alert-light {
  background: #fff;
  border-color: #c3ccd3;
  color: #626262 !important;
}
.alert.alert-light > i {
  color: #626262 !important;
}
.alert.alert-dark {
  background: #dbdbdb;
  border-color: #a2a2a2;
  color: #1b1e21 !important;
}
.alert.alert-dark > i {
  color: #1b1e21 !important;
}
section.messages {
  margin-top: -4px;
  box-shadow: inset 0 -2px 5px -1px rgba(0,0,0,0.4);
}
.toast {
  position: fixed;
  z-index: 9999;
  width: calc(100% - 2rem);
  max-width: 500px;
  bottom: 1rem;
  right: 1rem;
}
.toast .alert-don {
  border-radius: 0.25em;
  overflow: hidden;
  box-shadow: 0 1rem 1.5rem rgba(0,0,0,0.3);
  margin-bottom: 1rem;
  position: relative;
}
.toast .alert-don .alert-don-body {
  display: flex;
  align-items: stretch;
}
.toast .alert-don .alert-don-body.success {
  background: #d4edda;
  border-color: #6dbc7f;
  color: #155724 !important;
}
.toast .alert-don .alert-don-body.success i {
  color: #155724 !important;
}
.toast .alert-don .alert-don-body.info {
  background: #c1e7fa;
  border-color: #3fade7;
  color: #0c5460 !important;
}
.toast .alert-don .alert-don-body.info i {
  color: #0c5460 !important;
}
.toast .alert-don .alert-don-body.warning {
  background: #f4e5b6;
  border-color: #f5c538;
  color: #856404 !important;
}
.toast .alert-don .alert-don-body.warning i {
  color: #856404 !important;
}
.toast .alert-don .alert-don-body.danger {
  background: #f4cac9;
  border-color: #ee9190;
  color: #721c24 !important;
}
.toast .alert-don .alert-don-body.danger i {
  color: #721c24 !important;
}
.toast .alert-don .alert-don-body .action-toast {
  padding: 8px 8px 0 0;
}
.toast .alert-don .alert-don-body span {
  padding: 15px 20px 15px 0;
  flex-grow: 1;
}
.toast .alert-don .alert-don-body i:not(.icon-action-close) {
  font-size: 2rem;
  padding: 0.5rem;
  display: inline-flex;
  align-items: center;
}
.static-notification {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.25em;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  padding: 0.65em;
  margin: 0 0 20px 0;
  background: #f9fcf9;
  border: 0;
  color: #222;
}
.static-notification i {
  margin-right: 0.55rem;
  color: #000 !important;
}
.static-notification p {
  margin-bottom: 0;
}
.static-notification p small {
  opacity: 0.75;
  font-size: 90%;
}
.static-notification.status-primary {
  border-top: 5px solid #41a160;
}
.static-notification.status-primary i {
  color: #2e8149 !important;
}
.static-notification.status-secondary {
  border-top: 5px solid #9ca5ae;
}
.static-notification.status-secondary i {
  color: #858e96 !important;
}
.static-notification.status-info {
  border-top: 5px solid #0076b4;
}
.static-notification.status-info i {
  color: #0076b4 !important;
}
.static-notification.status-danger {
  border-top: 5px solid #f65a57;
}
.static-notification.status-danger i {
  color: #e74946 !important;
}
.static-notification.status-warning {
  border-top: 5px solid #ffcd38;
}
.static-notification.status-warning i {
  color: #e7ae02 !important;
}
.static-notification.status-dark {
  border-top: 5px solid #333;
}
.static-notification.status-dark i {
  color: #333 !important;
}
.gm-style .gm-style-iw {
  max-width: 400px !important;
  width: calc(100vw - 3rem);
/* border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: 3px solid #2e8149; */
  font-family: "Lato", sans-serif;
  font-weight: 400;
  padding: 0 !important;
}
.gm-style .gm-style-iw-t::after {
  display: none;
}
.gm-style .gm-style-p {
  padding: 0px;
}
.gm-style-iw-d {
  min-width: 100% !important;
  padding: 0;
  overflow: hidden !important;
}
.gm-style-iw-d h3 {
  width: calc(100% - 3rem);
}
.gm-style p {
  font-size: 16px;
}
.gm-ui-hover-effect {
  top: 1rem !important;
  right: 1rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  position: relative;
}
.gm-ui-hover-effect img {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 1.5rem !important;
  height: 1.5rem !important;
  top: 50%;
  left: 50%;
  margin: 0 !important;
}
.window-map {
  padding: 1rem;
}
@-moz-keyframes showProgress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes showProgress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@-o-keyframes showProgress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes showProgress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
#hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  z-index: 0;
  min-height: 600px;
}
#hero .hero-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1.5rem 2.5rem;
  color: #fff;
}
#hero .hero-wrapper .isologo {
  max-width: 300px;
  margin: 1rem auto;
  display: block;
  flex-grow: 0;
}
#hero .hero-wrapper .isologo img {
  width: 100%;
}
#hero .hero-wrapper h1 {
  margin: 0 0 1.5rem 0;
  font-weight: 900;
  text-align: center;
  max-width: 800px;
}
#hero .hero-wrapper p {
  font-size: 1.3rem;
  line-height: 130%;
  font-weight: bold;
  text-align: center;
  min-width: 100%;
  max-width: 700px;
}
#hero .hero-wrapper .hero-info {
  text-align: center;
}
#hero .hero-wrapper .hero-video {
  width: 100%;
}
#hero .hero-wrapper .hero-video div {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  box-shadow: 0 55px 40px -20px rgba(0,0,0,0.6);
}
#hero .hero-wrapper .hero-video div iframe {
  position: absolute;
  display: block;
  border: 4px solid #b2b4b6;
  overflow: hidden;
  border-radius: 0.5rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#hero .hero-landing {
  min-height: 100%;
}
a.landing-cta {
  padding: 15px 25px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background-color: #fff;
  color: #222;
}
a.landing-cta:hover {
  color: #fff;
  background-color: #222;
}
.don-slogan-cta {
  text-align: center;
  padding: 3rem 1rem;
  border-top: 5px solid #4aa354;
  border-bottom: 5px solid #4aa354;
}
.don-slogan-cta h2 {
  font-size: 2.3rem;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -0.1rem;
  margin-bottom: 2rem;
}
.don-slogan-cta img {
  width: 20%;
  max-width: 60px;
}
.don-slogan-cta h2,
.don-slogan-cta a {
  margin-bottom: 20px;
}
.flags {
  background: #333;
}
.flags span {
  color: #fff;
  font-weight: bolder;
}
.flags img {
  max-width: 20px;
}
.flags img.flag-no {
  opacity: 0.15;
}
.box-features {
  margin-top: 4rem;
}
#banner-icons {
  background-image: url("../img/icon-illustrations/banner-icons.png");
  height: 30px;
  background-repeat: repeat-x;
  overflow: hidden;
  display: block;
  background-size: auto 30px;
  margin: 40px 0;
  opacity: 0.2;
}
#banner-icons .row h2 {
  margin: 1rem 1rem 2rem 1rem;
  font-weight: 900;
  font-size: 2.25rem;
  line-height: 90%;
  text-align: center;
  margin-bottom: 4rem;
  position: relative;
}
.feature {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 1000px;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}
.feature div {
  max-width: 500px;
  order: 1;
}
.feature div h2 {
  margin: 1.5rem 0.5rem 1rem;
  font-size: 1.9rem;
  font-weight: 900;
  letter-spacing: -1px;
  position: relative;
  letter-spacing: -2px;
  max-width: 500px;
  line-height: 110%;
}
.feature div p {
  font-size: 1.1rem;
  color: #575757 !important;
  letter-spacing: -0.75px;
  line-height: 1.5;
}
.feature div span {
  margin: 0 0.5rem;
}
.feature img {
  order: 2;
  max-width: 500px;
  width: 100%;
}
#btn-landing-complejos {
  position: relative;
  border-radius: 1rem;
  margin: 1rem 1rem 2rem;
  overflow: hidden;
}
#btn-landing-complejos h3,
#btn-landing-complejos i {
  color: #fff;
  text-shadow: 0 5px 10px rgba(0,0,0,0.5);
}
.landing-card {
  border-radius: 1rem;
  overflow: hidden;
  padding: 1.5rem;
  background-color: #fff;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.18);
}
.landing-card i {
  color: #2e8149;
}
@media (min-width: 575px) {
  .don-slogan-cta h2 {
    font-size: 1.6rem;
  }
  .box-features .row h2 {
    font-size: 2.3rem;
    margin: 1rem auto 2rem auto;
  }
  .box-features .feature h2 {
    font-size: 2.1rem;
  }
}
@media (min-width: 992px) {
  .don-slogan-cta h2 {
    font-size: 2.3rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  #hero.hero-landing .hero-wrapper {
    flex-direction: row;
    max-width: 1200px;
    width: 100%;
  }
  #hero.hero-landing .hero-wrapper .hero-info,
  #hero.hero-landing .hero-wrapper .hero-video {
    width: 50%;
    max-width: 700px;
  }
  #hero.hero-landing .hero-wrapper .hero-info .isologo {
    margin: 1rem 0;
  }
  a.landing-link.landing-cta {
    margin-top: 20px;
  }
  .bg-cancha {
    background-position: center 20%;
  }
  .feature {
    margin: 0 auto 2.5rem auto;
  }
  .feature div {
    text-align: left;
    flex-grow: 1;
  }
  .feature div h2 {
    text-align: left !important;
    font-size: 2.3rem;
    margin: 0 0 1rem 0;
  }
  .feature img {
    max-width: 500px !important;
  }
  .feature .row h2 {
    font-size: 2.7rem;
  }
}
#feedback .hidden {
  text-align: center;
}
#feedback .hidden textarea {
  height: 80px;
  resize: none;
}
footer {
  flex-wrap: wrap;
  padding: 2em;
  background: #fff;
  color: #191919;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
footer #footer-links {
  width: 100%;
  max-width: 1000px;
}
footer ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  align-items: start;
  border-bottom: 1px solid #dee2e6;
  padding: 1rem 0;
}
footer ul li {
  margin-bottom: 10px;
  display: block;
}
footer ul li span {
  color: #6c757d;
  font-weight: 400;
  letter-spacing: -0.05rem;
}
footer ul li a {
  font-size: 14px;
}
footer ul li a:not(active),
footer ul li a:hover,
footer ul li a:visited {
  color: #2e8149 !important;
}
footer .follow-us a i {
  color: #222;
}
footer .follow-us a:hover i {
  color: #333;
}
footer .follow-us a:active i {
  color: #111;
}
@media screen and (min-width: 768px) {
  footer {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
  }
  footer li {
    display: inline-block;
  }
  footer div a {
    font-size: 1rem;
  }
  footer div li {
    margin-bottom: 1rem;
  }
  footer ul {
    flex-direction: row;
    flex-wrap: wrap;
  }
  footer ul li {
    flex-basis: 33.333333333333336% !important;
  }
  footer ul li:first-child {
    flex-basis: 100% !important;
  }
  footer.logged-admin {
    margin-left: 220px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 800px;
  }
}
.card {
  margin-bottom: 20px;
}
hr.dots {
  width: 100%;
  border: 0;
  border-top: 1px dashed #c6b3a0;
  display: block;
}
#game-headline {
  margin-top: 4px;
}
#game-headline.canceled {
  border-top: 3px solid #e74946 !important;
}
#game-headline.closed {
  border-top: 3px solid #222 !important;
}
#game-headline.played {
  border-top: 3px solid #0076b4 !important;
}
.match-in-list {
  position: relative;
}
.match-in-list.juegas {
  border-color: #41a160;
}
.match-in-list .date-places {
  position: relative;
}
.match-in-list .date-places .match-date {
  padding-right: 85px;
}
.match-in-list .date-places .lugares {
  white-space: nowrap;
  top: -2px;
  right: 0;
  position: absolute;
}
.match-in-list .match-title {
  font-size: 1rem;
  color: #222;
  margin-bottom: 0.5rem;
}
.share-match {
  float: right;
  z-index: 10;
  position: relative;
}
.match-in-list .share-match {
  margin: -26px -13px 0 0;
}
.match-in-list #partido-ficha .share-match {
  margin: -9px 0px 0 0;
}
.games-listed,
.column-match {
  margin-bottom: 30px;
}
.calendar {
  display: flex;
  background-color: #fff !important;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  border-left: 5px solid #91b971;
  align-items: center;
  position: sticky;
  top: 80px;
  z-index: 1;
  padding: 0.5rem;
  background: #fff;
  width: 100%;
  margin: 0 0 20px 0;
}
.calendar.calendar-game {
  top: unset;
  flex-direction: row;
}
.calendar .jugado {
  opacity: 0.3;
}
.calendar span {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.9rem;
  letter-spacing: -0.05rem;
  margin-right: 3px;
}
.calendar span.day {
  color: #e74946;
  font-weight: 900;
}
.calendar span.month,
.calendar span.weekday {
  color: #111;
}
@media (min-width: 600px) {
  .games-listed {
    display: flex;
    flex-wrap: wrap;
  }
  .calendar {
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075) !important;
    background-color: #fff !important;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-top: 5px solid #91b971;
    width: 55px;
    margin: 0 10px 0 0;
    flex-direction: column;
  }
  .calendar .weekday {
    display: none;
  }
  .calendar span {
    margin-right: unset;
  }
  .calendar span.day {
    font-size: 1.6rem;
  }
  .calendar span.month,
  .calendar span.weekday {
    margin-top: -4px;
  }
}
@media (min-width: 768px) {
  .games-listed .calendar {
    top: 140px;
  }
}
.match-title-date {
  display: flex;
}
small.pos-btn {
  text-align: center;
  color: #292929;
  letter-spacing: 0;
  text-transform: uppercase;
  display: block;
  margin-top: 5px;
}
.stats-plus-label {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-around;
}
.stats-plus-label li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stats-plus-label p {
  font-size: 2.2rem;
  font-weight: 900;
  margin-bottom: 0;
}
.stats-plus-label.reduced p {
  font-size: 1.8rem;
}
.stats-plus-label span {
  font-size: 0.8rem;
  font-weight: 900;
}
.stats-plus-label img {
  max-width: 16px;
  max-height: 16px;
}
.stats-plus-label .muted {
  opacity: 0.3;
}
.field-position {
  position: relative;
}
.field-position::after {
  background: #222;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.694rem;
  font-weight: bolder;
  padding: 2px 5px;
  border-radius: 4px;
  z-index: 10;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 25px;
  transform: translate(-50%, -50%);
  box-shadow: 0 10px 10px -5px rgba(0,0,0,0.5);
}
.field-position.no-position::after {
  content: "Posición no ingresada";
  background: #fff;
  color: #000;
  left: 50%;
}
.field-position.Arquero::after {
  content: "Arquero";
  transform: translate(0%, -50%);
  left: 10%;
}
.field-position.Defensor::after {
  content: "Defensor";
  left: 48%;
  top: 5px;
  transform: translate(-100%, -50%);
  box-shadow: 0 36px 14px -5px rgba(0,0,0,0.25);
}
.field-position.Mediocampista::after {
  content: "Mediocampista";
  left: 52%;
  top: 5px;
  transform: translate(0%, -50%);
  box-shadow: 0 36px 14px -5px rgba(0,0,0,0.25);
}
.field-position.Delantero::after {
  content: "Delantero";
  transform: translate(-100%, -50%);
  left: 90%;
}
@media only screen and (min-width: 600px) {
  .field-position {
    position: relative;
  }
  .field-position::after {
    font-size: 0.8rem;
    padding: 5px 10px;
    top: 32px;
    box-shadow: 0 20px 10px -10px rgba(0,0,0,0.65);
  }
  .field-position.no-position::after {
    left: 50%;
  }
  .field-position.Arquero::after {
    left: 10%;
  }
  .field-position.Defensor::after {
    top: 0px;
    left: 45%;
    box-shadow: 0 50px 14px -10px rgba(0,0,0,0.5);
  }
  .field-position.Mediocampista::after {
    top: 0px;
    left: 55%;
    box-shadow: 0 50px 14px -10px rgba(0,0,0,0.5);
  }
  .field-position.Delantero::after {
    left: 90%;
  }
}
.badges-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
}
.badges-grid img {
  margin: 0 auto;
}
@media only screen and (min-width: 475px) {
  .badges-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
#perfil .user-header {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
#perfil .user-header .actions-profile {
  position: absolute;
  left: calc(50% + 40px);
  top: 0;
}
#perfil .user-header {
  margin-left: auto !important;
  margin-right: auto !important;
}
#perfil .user-header p {
  color: #858e96;
}
#perfil .menu-slide-item .dropdown-menu {
  top: 30px !important;
}
#profile-completion .tasks {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  row-gap: 0.5rem;
}
#profile-completion .tasks li i {
  color: #2e8149;
}
#profile-completion .tasks span,
#profile-completion .tasks a {
  text-align: left;
  font-size: 0.833rem;
  font-weight: bold;
}
#profile-completion .tasks :not(.task-unchecked) span {
  color: #6c757d;
}
#profile-completion .tasks .task-unchecked i {
  opacity: 0.2;
}
#badges img {
  width: 50px;
  height: 50px;
}
@media only screen and (min-width: 600px) {
  #perfil .menu-slide-item .dropdown-menu {
    top: 0px !important;
  }
  #profile-completion .tasks {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 768px) {
  #perfil .user-header .user-info p {
    font-size: 1rem;
  }
  #profile-completion .tasks {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.confirmados-game {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 5px 10px;
}
.confirmados-game a {
  flex-grow: 1;
}
.confirmados-game i,
.confirmados-game a {
  color: rgba(0,0,0,0.7) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.w-icon {
  display: flex;
  align-items: center;
}
.w-icon i {
  margin-right: 3px;
}
.w-icon :not(i) {
  flex-grow: 1;
  margin-bottom: 0;
}
.availability-games {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}
@media only screen and (min-width: 600px) {
  .availability-games {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
.availability-games .entry {
  display: inline-flex;
  flex: 1;
  border: 1px solid #2e8149;
  padding: 0.75rem 0.25rem;
  border-radius: 0.5rem;
  flex-direction: column;
  align-items: center;
}
.availability-games .entry .title {
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
}
.availability-games .entry .value {
  font-size: 0.8rem;
  font-weight: bolder;
}
.ciudades-canchas ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}
.ciudades-canchas .card-body li {
  border-top: 1px solid #e1e1e1;
  padding: 10px 0;
}
.ciudades-canchas .card-body li:first-child {
  border: 0;
  padding-top: 0;
}
.ciudades-canchas .card-body li:last-child {
  padding-bottom: 0;
}
#cancha-ficha .venue-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (min-width: 600px) {
  #cancha-ficha .venue-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
#cancha-ficha .venue-actions div {
  text-align: center;
  margin-bottom: 20px;
}
.pregunta-item {
  box-shadow: 0 0 !important;
}
.pregunta-item::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  box-shadow: 0 5px 45px 0 rgba(0,0,0,0.15);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.pregunta-item.border-0::before {
  opacity: 1;
}
@media (min-width: 993px) {
  .content {
    min-height: 50%;
  }
}
.estado-convocatoria {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.estado-convocatoria.en-ficha {
  border-bottom: 1px solid #dfdfdf;
  margin: -20px -20px 10px;
  padding: 10px 20px 10px;
  background-color: #f9f9f9;
}
.estado-convocatoria .game-row {
  text-align: left;
  margin-right: 20px;
  flex-shrink: 0;
}
.invite-response {
  display: flex;
}
.invite-response a,
.invite-response span {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #dee2e6;
}
.invite-response a:first-child,
.invite-response span:first-child {
  margin-right: 5px;
}
.invite-response a i,
.invite-response span i {
  margin: 0 !important;
}
.invite-response a#reject-link i,
.invite-response span#reject-link i {
  color: #e74946 !important;
}
.invite-response a#reject-link.selected,
.invite-response span#reject-link.selected {
  background-color: #f4cac9;
  border-color: #ee9190;
}
.invite-response a#accept-link i,
.invite-response span#accept-link i {
  color: #2e8149 !important;
}
.invite-response a#accept-link.selected,
.invite-response span#accept-link.selected {
  background-color: #d4edda;
  border-color: #6dbc7f;
}
@media (min-width: 768px) {
  .card-body > .invite-answer {
    flex-direction: row;
    justify-content: space-between;
  }
  .card-body > .invite-answer span {
    display: inline-block;
    margin-bottom: 0;
  }
  .card-body > .invite-answer .invite-response {
    text-align: left;
  }
}
.scores {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: #222 !important;
}
.scores.minimum {
  border-bottom: 1px solid rgba(0,0,0,0.125);
  border-top: 1px solid rgba(0,0,0,0.125);
  margin: 20px 0;
  padding: 5px 0;
}
.scores.minimum .score-team-card {
  margin-bottom: 0px;
}
.scores.minimum .score-team-card .score-team {
  font-size: 0.9rem !important;
}
.scores .vs {
  display: none;
  font-weight: bold;
  font-size: 0.8rem;
}
.scores .score-team-card {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
}
.scores .score-team-card .score-team {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
  font-size: 1rem;
  font-weight: 900;
  order: 1;
  text-align: left;
}
.scores .score-team-card .score-numbers {
  flex-shrink: 0;
  font-size: 1.2rem;
  text-align: right;
  font-weight: bold;
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  order: 2;
}
.scores .score-team-card .score-numbers .no-result {
  background-color: transparent;
  border: 0;
  color: #191919;
}
.scores .score-team-card .scores .score-team,
.scores .score-team-card .scores .score-numbers {
  font-weight: bold;
  color: #191919;
  letter-spacing: -0.08rem;
  font-size: 1.4rem;
}
@media (min-width: 768px) {
  .scores {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .scores .vs {
    display: block;
  }
  .scores .score-team-card {
    max-width: 48% !important;
  }
  .scores .score-team-card .score-team {
    text-align: left;
    order: 1;
  }
  .scores .score-team-card .score-numbers {
    font-size: 1.6rem;
    text-align: center;
    order: 2;
  }
  .scores .score-team-card:last-child .score-team {
    order: 2;
    text-align: right;
  }
  .scores .score-team-card:last-child .score-numbers {
    order: 1;
  }
  .scores.minimum .score-team {
    padding: 0.325rem;
  }
}
.games-on-venue {
  -webkit-overflow-scrolling: touch;
  width: 100%;
}
.games-on-venue .placeholder {
  background: #fff;
  border: 2px dashed #dedede;
  position: relative;
}
.games-on-venue .placeholder a {
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.games-on-venue .placeholder {
  height: calc(100% - 30px);
}
@media only screen and (max-width: 991px) {
  .games-on-venue {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
@media only screen and (min-width: 992px) {
  .game-on-venue .match-in-list .match-title-date {
    height: 100px;
  }
  .games-on-venue {
    display: flex;
    flex-wrap: wrap;
  }
}
.table-hover tbody tr:hover {
  background-color: rgba(0,0,0,0.075);
}
.reservations .nav-link {
  height: 100%;
  padding: 1rem;
}
.reservation-table {
  border: 1px solid #dee2e6;
  border-top: 0;
}
.reservation-table tbody tr {
  height: 70px;
}
.reservation-table tbody tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,0.025);
}
.reservation-table tbody tr:hover {
  background-color: rgba(145,185,113,0.3);
}
.reservation-table th {
  border-bottom: 1px solid #dee2e6 !important;
  border-top: 0 !important;
  font-size: 0.8rem;
  background: #fff;
}
.reservation-table td {
  font-size: 14px;
}
.reservation-table th,
.reservation-table td {
  white-space: nowrap;
  vertical-align: middle;
}
#dashboard .card {
  height: calc(100% - 20px);
}
.button-select {
  display: flex;
  gap: 0.25rem;
}
.button-select .as-btn {
  flex: 1 1;
  text-align: center;
  margin: 0;
}
.button-select .as-btn span {
  font-size: 1rem;
}
.selected-calendar {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-top: 5px solid #91b971;
  position: sticky;
  top: 80px;
  z-index: 100;
  padding: 0.25rem;
  background: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075) !important;
  width: 55px;
  margin-right: 0.5rem;
  flex-direction: column;
}
.selected-calendar span {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: -0.05rem;
}
.selected-calendar span.weekday {
  font-size: 0.75rem !important;
}
.selected-calendar span.day {
  color: #c63e25;
  font-size: 1.6rem !important;
  margin-top: -0.25rem;
}
.selected-calendar span.month {
  color: #716664;
  margin-top: -4px;
  font-size: 0.8rem !important;
}
.sidebar-right {
  display: none;
  z-index: 2;
  background-color: rgba(255,255,255,0.9);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.sidebar-right .sidebar-body {
  background-color: #fff;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.175);
  width: 100%;
  max-width: 500px;
  position: fixed;
  z-index: 1000;
  top: 60px;
  right: 0;
  bottom: 60px;
  overflow-y: auto;
  padding: 1rem;
}
@media (min-width: 768px) {
  .sidebar-right .sidebar-body {
    background: #fafafa;
    bottom: 0;
    padding: 1rem 2rem;
  }
}
.schedule-container {
  width: 100%;
  min-height: 100vh;
}
.schedule-container button {
  cursor: pointer;
}
.schedule-container #sticky-bar .sticky-body {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.schedule-container #sticky-bar .sticky-body select {
  width: 100%;
  font-weight: bolder;
}
.schedule-container .navigation {
  display: flex;
}
.schedule-container .navigation button {
  margin-right: 0.5rem;
}
.schedule-container .schedule {
  width: 100%;
  max-width: 800px;
  margin: 3rem auto 0;
  padding: 0 1rem;
}
.schedule-container .schedule .day-schedule .appointment-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #222;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-weight: bold;
}
.schedule-container .schedule .day-schedule .appointment-card > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}
.schedule-container .schedule .day-schedule .appointment-card > div .info-reservation {
  flex-grow: 1;
  margin-left: 0.5rem;
  margin-bottom: 0;
}
.schedule-container .schedule .day-schedule .appointment-card > div .info-reservation span b {
  font-size: 0.8rem;
}
.schedule-container .schedule .day-schedule .appointment-card > div > .btn {
  box-shadow: 0 2px 5px rgba(0,0,0,0.35);
}
.schedule-container .schedule .day-schedule .appointment-card.slot-unavailable {
  background-color: #dee2e6 !important;
  background: repeating-linear-gradient(-45deg, transparent, transparent 4px, #c3ccd3 5px, #c3ccd3 7px);
}
.schedule-container .schedule .day-schedule .appointment-card.slot-unavailable::after {
  content: "Fuera de hora";
  color: #6c757d;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  background-color: #dee2e6 !important;
  border-radius: 0.25rem;
  border: 1px solid #c3ccd3;
}
.schedule-container .schedule .day-schedule .appointment-card.appointment-is-past:not(.slot-unavailable) {
  background-color: #c3ccd3;
}
.schedule-container .schedule .day-schedule .appointment-card.appointment-is-past:not(.slot-unavailable):not(.slot-available) {
  background-color: #dee2e6;
}
.schedule-container .schedule .day-schedule .appointment-card:not(.appointment-is-past).slot-available >div:first-child {
  width: 100%;
}
.schedule-container .schedule .day-schedule .appointment-card:not(.appointment-is-past).slot-available >div:first-child > button {
  width: 100%;
}
.schedule-container .schedule .day-schedule .appointment-card:not(.appointment-is-past):not(.slot-unavailable) {
  background-color: #cbddd0;
}
.schedule-container .schedule .day-schedule .appointment-card:not(.appointment-is-past):not(.slot-unavailable).slot-reserved {
  background-color: #fff;
  border: 1px solid #c3ccd3;
}
.schedule-container .schedule .day-schedule .appointment-card .modal-background {
  z-index: 1000;
  background-color: rgba(255,255,255,0.9);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.schedule-container .schedule .day-schedule .appointment-card .modal-background #edit-modal {
  background-color: #fff;
  width: 100%;
  max-width: 500px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 2rem;
  color: #222;
  padding-bottom: 4rem;
}
.schedule-container .schedule .day-schedule .appointment-card .modal-background #edit-modal .edit-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.schedule-container .schedule .day-schedule .appointment-card .modal-background #edit-modal form .form-100 {
  margin-bottom: 1rem;
}
@media (min-width: 600px) {
  .appointment-card > div .info-reservation span b {
    font-size: 1rem !important;
  }
}
.container__premium {
  display: flex;
  flex-direction: column;
}
.container__premium .more-info {
  margin: 0 0 1rem;
  font-size: 0.9rem;
  text-align: left;
  color: #6c757d;
}
.container__premium .more-info p {
  margin-bottom: 0.75rem;
}
.container__premium .more-info p:last-child {
  margin-bottom: 0;
}
.container__row {
  align-items: center;
  display: flex;
  padding: 1rem 0;
}
.container__row:not(:first-child) {
  border-top: 1px solid #dee2e6;
}
.container__row.blank {
  justify-content: center;
  padding: 1.5rem 0;
}
.container__feature {
/* Take available width */
  text-align: left;
  flex: 1;
  font-size: 0.8rem;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}
.container__feature span {
  white-space: normal;
  text-align: left;
  margin-left: 0.5rem;
}
.container__model {
/* Center the content */
  display: flex;
  justify-content: center;
  min-width: 25%;
/* Spacing */
  font-size: 0.8rem;
  margin-left: 1rem;
}
.form-100 {
  width: 100%;
}
.form-row {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 20px !important;
}
textarea {
  resize: vertical;
  min-height: 100px;
  line-height: 120% !important;
}
textarea,
select,
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="time"],
.form-control {
  border-radius: 5px;
  border: 1px solid #c3ccd3;
  background: #fff;
  padding: 0px 10px;
  min-height: 42px;
  line-height: 1.5rem !important;
}
textarea:disabled,
select:disabled,
input[type="text"]:disabled,
input[type="email"]:disabled,
input[type="tel"]:disabled,
input[type="number"]:disabled,
input[type="password"]:disabled,
input[type="date"]:disabled,
input[type="time"]:disabled {
  background: #dee2e6;
}
::placeholder {
  font-style: italic !important;
  color: #9ca5ae;
}
textarea:focus,
select:focus,
input:focus {
  border-color: #90a497;
  box-shadow: 0 0 8px #a5cab0;
  outline: 2px solid #91b971;
}
.picker__header select {
  font-size: 16px !important;
  padding: 5px !important;
}
.picker__header .picker__year {
  font-style: unset;
  font-size: unset;
}
.container-input.error > input,
.container-input.error > select,
.container-input.error > textarea {
  border-color: #e74946;
}
.container-input.success > input,
.container-input.success > select,
.container-input.success > textarea {
  border-color: #2e8149;
}
.input-group {
  display: flex;
  flex-wrap: nowrap;
}
.input-group input {
  width: 100%;
  border-radius: 5px 0 0 5px !important;
}
.input-group input:not(:first-child) {
  border-radius: 0 5px 5px 0 !important;
}
.input-group-append {
  display: flex;
}
.input-group-append > :last-child {
  border-radius: 0 5px 5px 0 !important;
}
.input-group-text {
  display: flex;
  justify-content: center;
  min-width: 31px;
  padding: 0 0.25rem !important;
  background: #fff;
}
.input-group-text,
span.input-group-text i {
  color: #626262;
}
.minus-plus span.input-group-text {
  max-width: 31px;
  cursor: pointer;
  background: #dee2e6;
}
.minus-plus span.input-group-text i {
  font-size: 32px;
}
.minus-plus span.input-group-text:active {
  background-color: #c3ccd3;
  box-shadow: inset 0 1px 4px 0 rgba(0,0,0,0.3);
}
.minus-plus span.input-group-text:first-child {
  border-right: 0;
  margin-left: 0;
}
label {
  font-weight: 700;
  font-size: 0.9rem;
  color: #6c757d;
  margin-bottom: 0.5rem;
  width: 100%;
}
label.required {
  position: relative;
  padding-left: 10px;
}
label.required::before,
label.required::after {
  color: #be1414;
  font-weight: bold;
}
label.required::before {
  content: "*";
  margin-right: 5px;
  position: absolute;
  top: 2px;
  left: 0px;
  font-size: 1.2rem;
}
label.required::after {
  content: "- OBLIG.";
  letter-spacing: 0.3px;
  font-weight: bolder;
  margin-left: 4px;
  font-size: 0.5rem;
  position: absolute;
  top: 5px;
}
label.inline {
  width: unset;
  flex-grow: 1;
  margin-bottom: 0;
}
label.as-btn,
label.checkbox,
label.radio,
label.switch {
  width: unset;
}
label.as-btn,
label.checkbox,
label.radio {
  color: #222;
  margin-left: 0;
  font-size: 1rem;
  font-weight: 500;
}
label.as-btn input,
label.checkbox input,
label.radio input,
label.switch input {
  display: none;
}
label.as-btn span,
label.checkbox span,
label.radio span,
label.switch span {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
label.as-btn {
  font-weight: 700;
}
label.as-btn span {
  display: block;
  font-size: 0.9rem !important;
  border-radius: 50px;
  border: 1px solid #9ca5ae;
  height: auto !important;
  padding: 0.5rem 0.75rem !important;
  background-color: #fff;
}
label.as-btn span:hover {
  border: 1px solid #2e8149;
  background-color: #dee2e6;
}
label.as-btn.disabled,
label.as-btn.disabled span {
  pointer-events: none;
}
label.as-btn input:checked + span {
  border: 1px solid #2e8149;
  background-color: #41a160;
  color: #fff /* $color-dark; */;
}
label.as-btn.disabled span {
  border: 1px solid #9ca5ae;
  background-color: #dee2e6;
  color: #9ca5ae;
}
label.as-btn.disabled input:checked + span {
  border: 1px solid #dee2e6;
  background-color: #9ca5ae;
  color: #dee2e6;
}
label.as-btn.circle span {
  width: 30px;
  height: 30px !important;
  padding: 0 !important;
  line-height: 30px;
}
label.checkbox,
label.radio {
  position: relative;
  padding-left: 30px;
  line-height: 26px;
  margin-right: 10px;
}
label.checkbox span,
label.radio span {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  border: 1px solid #9ca5ae;
  background-color: #fff;
}
label.checkbox span {
  border-radius: 10px;
}
label.radio span {
  border-radius: 50%;
}
label.checkbox input:checked ~ span,
label.radio input:checked ~ span {
  background-color: #41a160;
}
label.checkbox input:disabled ~ span,
label.radio input:disabled ~ span {
  background-color: #dee2e6;
}
label.checkbox input:disabled:checked ~ span,
label.radio input:disabled:checked ~ span {
  background-color: #9ca5ae;
}
label.checkbox span:after,
label.radio span:after {
  content: "";
  position: absolute;
  display: none;
}
label.checkbox input:checked ~ span:after,
label.radio input:checked ~ span:after {
  display: block;
}
label.checkbox span:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
label.radio span:after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
}
label.switch {
  position: relative;
  display: inline-block;
  max-width: 40px;
  min-width: 40px;
  height: 23px;
  margin-bottom: 0;
}
label.switch span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b4d7c0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
label.switch.pronto span {
  background-color: #dbcfcf;
}
label.switch.pronto::after {
  content: "PRONTO";
  position: absolute;
  font-size: 0.7rem;
  background: #c1e7fa;
  color: #0c5460;
  padding: 0.3rem;
  border-radius: 50px;
  bottom: -8px;
  right: -5px;
  transform: rotate(3deg);
}
label.switch span:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
label.switch input:checked + span {
  background-color: #41a160;
}
label.switch input:checked + span:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}
label.switch span {
  border-radius: 28px;
}
label.switch span:before {
  border-radius: 50%;
}
input[type="radio"],
input[type="checkbox"] {
  width: 1.2rem;
  height: 1.2rem;
  vertical-align: middle;
}
#mdl-textfield-venue-name {
  position: relative;
}
#suggest_venue_address {
  position: absolute;
  z-index: 1;
  top: 15px;
  left: 15px;
  right: 15px;
  width: calc(100% - 30px);
  border: 1px solid #666;
}
#user-search {
  position: relative;
  display: inline-block;
  margin-left: 0;
  width: auto;
/* min-width: 300px; */
}
#user-search input {
/* min-width: 300px; */
  display: inline-block;
  padding-left: 5px;
}
#clear-searchbox {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0px;
  background-color: transparent;
  border: 0;
  width: 40px;
  height: 40px;
}
#clear-searchbox i {
  color: #2e8149;
  line-height: 40px;
}
.form-row {
  position: relative;
  text-align: left;
  margin-bottom: 15px;
}
.field-notification {
  margin-top: 0.35em;
  font-size: 0.9em;
  color: #e74946;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
}
.field-notification i {
  color: #e74946;
}
.field-notification span {
  margin-top: 3px;
}
input[type=range] {
  border: 0 !important;
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px !important;
  cursor: pointer;
  animate: 0.2s;
  background: #2e8149;
  border-radius: 1.3px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #2e8149;
}
input[type=range]::-webkit-slider-thumb {
  height: 30px;
  width: 20px;
  border: 0 !important;
  background-color: transparent;
  background-position: center;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}
input.star[type=range]::-webkit-slider-thumb {
  background-image: url("../img/icon-illustrations/il_star.svg");
}
input.ball[type=range]::-webkit-slider-thumb {
  background-image: url("../img/icon-illustrations/il_ball.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center 5px;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  background: #2e8149;
  border-radius: 1.3px;
}
input[type=range]::-moz-range-thumb {
  height: 30px;
  width: 20px;
  border: 0 !important;
  background-color: transparent;
  background-position: center;
  cursor: pointer;
}
input.star[type=range]::-moz-range-thumb {
  background-image: url("../img/icon-illustrations/il_star.svg");
}
input.ball[type=range]::-moz-range-thumb {
  background-image: url("../img/icon-illustrations/il_ball.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center 5px;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  background: #2e8149;
  border-radius: 3px;
  color: transparent;
}
input[type=range]::-ms-thumb {
  height: 30px;
  width: 20px;
  border: 0 !important;
  background-color: transparent;
  background-position: center;
  cursor: pointer;
}
input.star[type=range]::-ms-thumb {
  background-image: url("../img/icon-illustrations/il_star.svg");
}
input.ball[type=range]::-ms-thumb {
  background-image: url("../img/icon-illustrations/il_ball.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center 5px;
}
.user-avatar-name {
  display: flex;
  align-items: center;
}
.avatar-don {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  overflow: hidden;
  background-color: #dee2e6;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  vertical-align: unset;
  margin-right: 0.35rem;
}
.avatar-don.no-bg {
  background: none !important;
}
.avatar-don.av-xs {
  width: 16px;
  height: 16px;
}
.avatar-don.av-sm {
  width: 24px;
  height: 24px;
}
.avatar-don.av-md {
  width: 32px;
  height: 32px;
}
.avatar-don.av-lg {
  width: 48px;
  height: 48px;
}
.avatar-don.av-xl {
  width: 64px;
  height: 64px;
}
.avatar-don.av-xxl {
  width: 100px;
  height: 100px;
}
.avatar-don.unknown-player::after {
  content: "?";
  font-weight: 900;
  font-size: 1rem;
  color: #9ca5ae;
}
.avatar-group {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
}
.avatar-group .avatar-don {
  margin-right: -8px !important;
  border: 1px solid #fff;
}
.holder-vue {
  margin-bottom: 2rem;
}
.box-friends {
  padding: 1rem;
  margin-bottom: 3rem;
  border: 1px solid #c3ccd3;
  background-color: #fff;
  border-radius: 0.25rem;
}
.box-friends .holder-vue {
  margin-bottom: 0 !important;
}
.user-search {
  vertical-align: top;
  line-height: 36px;
}
.user-search input {
  width: 100%;
}
.user-search button {
  padding-left: 5px;
  width: 40px;
}
.user-search-results {
  position: absolute;
  z-index: 1;
  width: 100%;
  max-height: 350px;
  padding: 0rem 0rem 2.5rem;
  overflow: hidden;
  display: flex;
  background-color: #fff;
  box-shadow: 0 1px 15px rgba(0,0,0,0.2);
  border-radius: 0.25rem;
}
.user-search-results > div {
  max-height: 100%;
  overflow-y: auto;
  padding: 1rem;
  width: 100%;
  position: relative;
}
.user-search-results .clean-search {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  z-index: 1;
  border-top: 1px solid #dee2e6;
}
.box-user {
  width: 100%;
  margin-bottom: 0.25rem;
}
.box-user .inner-user {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
}
.box-user .inner-user:hover {
  background-color: #e7efe9;
}
.box-user .inner-user .user-info {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.box-user .inner-user .user-info .user-username span {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
}
.board-players {
  display: flex;
  flex-direction: column;
}
.board-players img.ball {
  width: 26px !important;
}
.board-players .board-players-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.board-players .player {
  flex-grow: 1;
}
.board-players .player a,
.board-players .player span.unknown-player {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.board-players .player span.user-username {
  color: #2e8149;
}
.board-players .player span:not(.user-username) {
  color: #292929;
}
.board-players .board-players-row .board-players-cell {
  display: inline-flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
  min-width: 40px;
}
.board-players .board-players-row .board-players-cell a {
  display: inline-flex;
  align-items: center;
}
#notas .card {
  height: calc(100% - 20px);
}
.tema-nota_image {
  max-height: 300px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.tema-nota_header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 1rem 1rem 0 0;
}
.tema-nota_header .tema-nota_headline {
  font-size: 2.2rem;
  line-height: 100%;
  margin-bottom: 2rem;
}
.tema-nota_header .tema-nota_intro {
  font-size: 1.3rem;
  color: #6c757d;
}
.tema-nota_header.w-image {
  margin-top: -100px !important;
}
.tema-nota_header,
.tema-nota_body {
  padding: 1rem 2rem;
}
@media (min-width: 768px) {
  .tema-nota_image {
    height: 400px;
    max-height: unset;
  }
  .tema-nota_image img {
    position: absolute;
    transform: translate(-50%, -50%);
    min-height: 100%;
    top: 50%;
    left: 50%;
  }
  .tema-nota_header.w-image {
    margin-top: -150px !important;
  }
  .tema-nota_header .tema-nota_headline {
    font-size: 2.7rem;
  }
}
.calendar-pick .calendar-pick-day {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  border-top: 3px solid #91b971;
  padding: 0.5rem;
  background: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075) !important;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}
.calendar-pick .calendar-pick-day span {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.9rem;
  letter-spacing: -0.05rem;
  margin-right: 5px;
}
.calendar-pick .calendar-pick-day span.day {
  color: #c63e25;
  font-size: 1.5rem;
}
.calendar-pick .calendar-pick-day span.month {
  color: #716664;
}
