
html
  background: $color-background !important
  scroll-behavior: smooth
html
body
  color: $color-text
  font-family: "Lato", sans-serif
  font-size: 16px
  line-height: 1.2
  letter-spacing: -0.03rem
  height: 100%
  text-rendering: optimizeLegibility !important
  -webkit-font-smoothing: antialiased /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale /* Firefox */
  font-feature-settings: "kern" 1
  font-kerning: normal
  background: unset !important
  box-sizing: border-box

::selection
  color: $color-dark-light
  background: $color-primary-light


/*********************************************************************************************************************************************/
/*********************************************************************************************************************************************/
/* MUST LOG IN */
/*********************************************************************************************************************************************/
.login-actions
  width: 250px
  a
    &.btn
      width: 100%
    &.social
      position: relative
      display: flex
      align-items: center
      img
        height: 24px
        width: 24px
        margin-right: 0.5rem
    &:not(:last-child)
      margin-bottom: 1rem

.auth-don
  input:not(:last-child)
      margin-bottom: 1rem
  button
    width: 100%



#block
  padding: 2rem 0 0
  .wrapper-login
    display: flex
    flex-direction: column
    height: 100%
    #brand
      display: block
      margin: 0 auto 20px auto
      max-width: 200px
      width: 100%
    .promo-login
       max-width: 90%
       margin: 0 auto auto
      .login-banner
        padding: 1.5rem 1.5rem 0
        border-radius: 1rem
        background: white
        max-width: 500px
      h1
        text-align: center
    .access-login
      margin: 1.5rem
      width: 80%
      padding: 1.5rem
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      align-self: center
      background-color: white
      border: 1px solid $color-grey-1
      border-radius: 1rem

@media (min-width: 768px)
  #block
    top: 0
    left: 0
    right: 0
    bottom: 0
    position: absolute
    padding: unset
    .wrapper-login
      flex-direction: row
      .promo-login
        margin-bottom: unset
        padding: 2rem
        width: 60vw
        height: 100%
        justify-content: center
        align-items: center
        .login-banner
          padding: 1.5rem
        h1
          max-width: 60%
      .access-login
        width: 40vw
        margin: 0
        padding: 3rem
        height: 100%
        border: unset
        border-radius: unset
        border-left: 1px solid $color-grey-1

@media (min-width: 1200px)
  .wrapper-login
    .promo-login
      width: 70vw !important
    .access-login
      width: 30vw !important

/*********************************************************************************************************************************************/
/* NAV & SUBNAV */
/*********************************************************************************************************************************************/
nav
  &.navbar
    .avatar-don
        margin-right: 0
    position: fixed
    z-index: 1030
    background: white
    display: block
    padding: 0
    height: 60px
    border-top: 1px solid $color-grey-1
    right: 0
    left: 0
    bottom: 0
    .container
      display: block !important
      height: 100%
      ul
        display: flex
        flex-direction: row
        margin: 0
        padding: 0
        height: 60px
        justify-content: space-between
        li
          list-style: none
          height: 100%
          vertical-align: middle
          &.brand-title
            flex-grow: 1 !important
            img
              height: 32px !important
          a
            display: inline-flex
            flex-direction: column
            align-items: center
            justify-content: center
            height: 100%
            &:hover
            &:active
              text-decoration: none
            &.nav-link
              padding: .35rem
              i
              span
                color: $color-text
              i
                font-size: 32px
              span
                font-weight: bold
                font-weight: 900
                font-size: 0.6rem
                text-transform: uppercase
                letter-spacing: 0.02rem
                margin-top: 0.25rem
                display: none
              .icon-user-admin
                color: $color-primary !important
                background: $color-background
    &.logged
    &.admin-venue
      border-top: 1px solid $color-grey-2
      li
        &.brand-title
          display: none
        &:not(.brand-title)
          text-align: center
    &.guest
      top: 0
      bottom: unset !important
    &.guest
      border-bottom: 1px solid $color-grey-1
    &.backend
      background-color: $color-grey-1
      border-color: $color-grey-2
    &.admin-venue
      background-color: $color-secondary-light
      li.active
        background-color: $color-secondary-dark
        i
        span
          color: white !important
    &.logged
      li.active
        border-top: 2px solid $color-primary !important
        i
        span
          color: $color-primary-dark !important
    &.guest
      border-top: unset !important
      li
        i
        span
          color: $color-primary-dark !important
      

@media (min-width: 600px)
  a
    &.nav-link
      span
        display: unset !important

@media (min-width: 768px)
	nav
    &.navbar
      li
        &.brand-title
          display: block !important
      &.navbar
      &:not(.admin-venue)
        top: 0 !important
        bottom: unset
        border-top: unset !important
        border-bottom: 1px solid $color-grey-1
        height: 60px
      &:not(.admin-venue)
        li
          &.active
            border-bottom: 2px solid $color-primary !important
            border-top: 0 !important
      &.admin-venue
        border-bottom: 0
        bottom: 0
        left: 0
        right: unset
        height: 100%
        width: 220px
        border-right: 1px solid $color-grey-2
        .container
          padding: 0
          ul
            display: flex !important
            flex-direction: column
            li
              &:first-child
                flex-grow: unset !important
                display: flex !important
                justify-content: space-between
                border-bottom: 1px solid $color-secondary-dark
                //margin-bottom: 1rem
                padding: 0.75rem 0
                a
                    width: unset !important
              a
                width: 100%
                text-align: left !important
                height: unset
                &.nav-link
                  flex-direction: row
                  padding: .5rem 1rem !important
                  height: unset
                  justify-content: start
                  width: 100%
                  i
                  span
                    color: $color-dark
                  span
                    font-size: 0.7rem !important
                  i
                    margin-right: 0.25rem
              &.active
                background-color: $color-secondary-dark
                i
                span
                  color: white !important
		

/* --------------------------- */
/* ---- MENU DRAWER ----------------------- */

.noScroll
  overflow: hidden

#sidebar-drawer
  -webkit-overflow-scrolling: touch
  display: none
  position: fixed
  z-index: 9999
  top: 0
  left: 0
  width: 100%
  height: 100%
  justify-content: center
  align-items: center
  .bg
    position: absolute
    display: block
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: white
    z-index: -1
  a
    &:hover,
    &:active
      text-decoration: none
  .drawer-content
    overflow: auto
    position: relative
    width: 90%
    max-width: 600px
    height: auto
    max-height: 100%
    background-color: white
    .drawer-header
      width: 100%
      height: 70px
      top: 0
      z-index: 1029
      position: -webkit-sticky
      position: sticky
      background-color: white
      padding: 10px 0
      display: flex
      justify-content: space-between
      align-items: center
      img.logo
        width: 200px
    .drawer-body
      overflow: hidden
      list-style: none
      padding: 0
      display: flex
      flex-wrap: wrap
      max-height: calc(100% - 70px)
      width: 100%
      .row
        padding: 10px
      .drawer-item
        margin-bottom: 1rem
        box-shadow: 0 1px 2px 1px rgba(0,0,0,0.1)
        border-radius: 0.35rem
        a
          display: flex
          flex-direction: column
          height: 100%
          padding: 8px 10px 10px
          span:not(.hint)
            font-size: 0.8rem
            font-weight: 700
            color: $color-dark
            display: flex
            align-items: center
            .icon-don
              //font-size: 32px
              color: $color-primary-light
            .icon-don
            img
              margin-right: 5px
          span.hint
            margin-top: 5px
            display: none
            font-size: 0.8rem
            color: $color-grey-4
        &.active
          background: $color-grey-1
          opacity: 0.5
        &.admin-access
          i
            color: $color-primary !important
        &.highlighted
          a
            span
              font-weight: bolder
              font-size: 1.2rem
              &.hint
                font-size: 0.8rem
                font-weight: 700

@media (min-width: 601px)
  #sidebar-drawer .drawer-item
    margin-bottom: 30px
    span.hint
      display: block !important

/* CONSENT */
[aria-hidden='cookieconsent'] {
    font-family: "Lato", sans-serif;
    font-size: 0.9rem;
    position: fixed;
    bottom: 20px !important;
    max-width: calc(100% - 40px) !important
    left: 20px !important;
    padding: 1rem !important;
    overflow: auto;
    border: 1px solid #8ec760;
    background-color: white !important;
    box-shadow: 0 2px 10px rgba(0,0,0,.1);
}
@media only screen and (min-width: 400px){
    [aria-hidden='cookieconsent'] {
        max-width: 360px !important
    }
}
.cc-message {
    display: flex;
    flex-grow: 1 !important;
    margin-bottom: 1em;
}
.cc-floating .cc-compliance>.cc-btn {
    flex: unset !important;
    align-self: flex-end;
}
.cc-floating .cc-compliance {
    display: flex;
    justify-content: flex-end;
}
.cc-btn.cc-dismiss {
    background-color: #222 !important;
}


.fb_iframe_widget_fluid_desktop iframe {
    width: 100% !important;
}
/* --------------------------- */
/* --------------------------- */
/* CONTENT */
/* --------------------------- */
main
  background-color: $color-background
  padding-bottom: 50px
  min-height: 100%
  box-shadow: 0 5px 15px rgba(0,0,0,0.2)
  .container
    margin-top: 20px
  &:not(.logged)
    &:not(.logged-admin)
      padding-top: 60px

@media (min-width: 768px)
  &.logged-admin
    margin-left: 220px;





.content
  margin: 0 auto 70px auto
  padding-top: 80px


main
  #sticky-bar
    position: sticky
    position: -webkit-sticky
    top: 59px
    left: 0
    width: 100%
    padding: 1rem
    background-color white
    border-bottom: 1px solid $color-grey-1
    z-index: 1000;
    .sticky-body
      width: 100%
      margin: auto
  &:not(.logged-admin)
    #sticky-bar
      z-index: 2
  &.logged
  &.logged-admin
    #sticky-bar
      top: -1px

@media (max-width: 576px)
  .sticky-body
    max-width: 540px

@media (min-width: 768px)
  main
    #sticky-bar
        .sticky-body
            //padding: 0 1rem
            max-width: 720px
    &.logged
    &.logged-admin
      #sticky-bar
        top: 59px
    &.logged	
        padding-top: 60px
    &.logged-admin
      #sticky-bar
        top: 0 !important
        z-index: 3
        .sticky-body
          max-width: unset !important

@media (min-width: 992px)
  main
    #sticky-bar
        .sticky-body
          max-width: 800px

#games-search-box {
  display: none;
}

#filter {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1031;
}
#filter .bg {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.9;
  z-index: -1;
}
#change-location-map{
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  z-index: 1;
}
#change-location-map .alert {
  position: absolute;
  z-index: 2;
  top: -15px;
  left: 50%;
  min-width: 260px;
  transform: translateX(-50%);
}
#change-location-map #map {
  width: 100%;
  height: 100%;
}

.filter-content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%
  width: 90%;
  max-width: 400px;
  // min-height: 90%;
  max-height: 600px;
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid $color-grey-2;
}
#filter .filter-header,
#filter .filter-footer {
  position: absolute;
  height: 70px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  background-color: white;
}
#filter .filter-header {
  top: 0;
}
#filter .filter-footer {
  bottom: 0;
  justify-content: flex-end;
}

#filter .filter-body {
  overflow-y: auto;
  padding: 30px;
  display: block;
  width: 100%;
  margin: 70px 0;
  max-height: calc(90vh - 140px);
}



// fondo de bloqueo de los modales
.modal-backdrop {
    background-color: white;
    opacity: 0.9;
}


#show-install-banner
  display: none !important

