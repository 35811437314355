
/* TYPOGRAPHY */
@font-face {
    font-family: 'trashhand';
    src: url('../fonts/TrashHand-webfont.eot');
    src: url('../fonts/TrashHand-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/TrashHand-webfont.woff') format('woff'),
         url('../fonts/TrashHand-webfont.ttf') format('truetype'),
         url('../fonts/TrashHand-webfont.svg#trashhandregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

/* Typescale 1.2 Minor-Third */
/*
(Not used) 2.488rem;
h1 2.074rem;
h2 1.728rem;
h3 1.44rem;
h4 1.2rem;
h5 1rem;
h6, menu-slide 0.833rem;
small, text-small, badge 0.694rem
*/



h1, h2, h3, h4, h5,h6, .font-1, .font-2, .font-3, .font-4
  font-weight: 900

h1.display, .display
  font-size: 2.488rem
  line-height: 120%

@media (max-width: 600px)
  h1.display
    font-size: 2.3rem

h1, .font-1
  font-size: 2.074rem
  letter-spacing: -0.12rem
h2, .font-2
  font-size: 1.728rem
  letter-spacing: -0.07rem
h3, .font-3
  font-size: 1.44rem
  letter-spacing: -0.05rem
h4, .font-4
  font-size: 1.2rem
  letter-spacing: -0.05rem
h5, .font-5
  font-size: 1rem
  letter-spacing: -0.05rem
h6, .font-6
  font-size: 0.833rem
  letter-spacing: -0.05rem

@media (min-width: 600px)
  h1
    text-align: center
    &.display
        font-size: 2.986rem

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a
  color: $color-dark !important






.trashhand
  font-family: "trashhand", 'lato', sans-serif
  font-weight: 400 !important
  letter-spacing: -1px !important

.font-weight-bolder
  font-weight: 900
.text-small, small
  font-size: 0.694rem
  font-weight: 700
.text-no-break
  white-space: nowrap !important
.text-break
  white-space: normal !important
.text-muted
  color: $color-text-muted
.text-white
  color: white !important
.text-black
  color: $color-dark !important
.text-primary
  color: $color-primary !important
.text-secondary
  color: $color-secondary !important
.text-danger
  color: $color-danger !important

.text-ellipsis
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.proper-read
  line-height: 1.5
  &.big
    font-size: 1.1rem
  

/* INTERNAL MENU, LINKS AND BUTTONS ***************************/
/**************************************************************/

/* REMOVE OUTLINE FOR ALL*/
textarea:hover,
input:hover,
textarea:active,
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
    box-shadow: none !important
    // -webkit-appearance: none !important

a
button
.btn
    font-weight: 700

a
    &:not(.btn)
        color: $color-text-link
    &:hover
    &:active
        color: $color-text-link
        text-decoration: none
    &:hover:not(.btn)
        color: $color-primary-light
    &:active:not(.btn),
    &:visited:not(.btn)
        color: $color-primary-dark


.btn
  white-space: normal
  border: 0
  padding: 0.5rem 0.75rem
  display: inline-flex
  justify-content: center
  align-items: center
  margin: 0
  &.disabled
    opacity: 0.25 !important
    pointer-events: none
  &:focus
    box-shadow: 0 0 0 0.2rem rgba(108,117,125,.3) !important
  &.btn-no-style
    background-color: transparent !important
  i
    color: unset !important
    margin-right: .25rem
  &.btn-icon
    padding: 0.5rem
    i
      margin-right: 0
  &.btn-lg
    line-height: 1.5rem
    padding: 0.75rem 1rem
    &.btn-icon
      padding: 0.5rem
      i
        font-size: 32px
  &.btn-sm
    padding: 0.25rem 0.5rem
    font-size: 0.875rem
    line-height: 1.5rem
    &.btn-icon
      padding: 0.25rem
    i
      margin-right: 0
  &.btn-full-width
    width: 100%
    margin-bottom: 1rem
  &.btn-full-height
    height: 100%
  &.btn-full-width
  &.btn-full-height
    display: flex
  &.btn-wrap
    white-space: normal

.btn
  &.btn-primary
    color: white !important
    background-color: $color-primary
    &:hover
    &:active
      background-color: $color-primary-light !important
  &.btn-secondary
    color: white
    background-color: $color-secondary-dark
    &:hover
    &:active
      background-color: $color-secondary !important
  &.btn-info
    background-color: $color-info
    color: white !important
    i
        color: white !important
    &:hover
    &:active
      background-color: $color-info-light
  &.btn-warning
    background-color: $color-warning
    color: $color-dark !important
    i
        color: $color-dark !important
    &:hover
    &:active
      background-color: $color-warning-light
  &.btn-danger
    background-color: $color-danger
    &:hover
    &:active
      background-color: $color-danger-light
  &.btn-dark
    background-color: $color-dark
    color: white !important
    i
        color: white !important
    &:hover
    &:active
      background-color: $color-dark-light
  &.btn-link
    box-shadow: unset
    background-color: transparent
    text-decoration: none !important
    &:not(.text-danger)
    &:not(.text-black)
        color: $color-primary
        i
            color: $color-primary
    &:hover
    &:active
      background-color: rgba(17, 45, 26, 0.15) !important
  &.btn-light
    background: white
    border: 1px solid $color-grey-3
    &:hover
    &:active
      background-color: $color-grey-1
      border-color: $color-grey-5

.dropdown-item
  &:hover
  &:active
    background-color: rgba(17, 45, 26, 0.15) !important

ul.menu-slide
  white-space: nowrap
  overflow-y: hidden
  overflow-x: scroll
  justify-content: start
  display: flex
  width: 100%
  padding: 0px
  margin: 0px 0px -1px !important
  border-bottom: 0px !important
  -webkit-overflow-scrolling: touch
  li
    display: inline-flex !important
    margin-right: -4px !important
    padding: 0
    border-bottom: 1px solid $color-grey-1
    align-items: center
    a
      text-transform: uppercase
      color: $color-primary !important
      font-size: 0.833rem
      padding: 0.5rem 0.75rem
      border-radius: 0.25rem
      text-decoration: none
      font-weight: 700
      i
        line-height: 1rem !important
        color: $color-primary !important
    &:not(.active)
      a
        &:hover
          background-color: rgba(46,129,73,0.1)
    &.disabled
      span
        text-transform: uppercase
        color: $color-grey-2 !important
        font-size: 0.833rem
        padding: 0.5rem 0.5rem
        border-radius: 0.25rem
        text-decoration: none
        font-weight: 700
    &:last-child
      margin-right: 0 !important
    &.active
      border-bottom: 1px solid $color-dark !important
      > a
        font-weight: 900
        color: $color-text !important

@media (min-width: 600px)
  .menu-slide
      justify-content: center !important
      white-space: normal !important
      overflow-x: hidden !important
      


/*********************************************************************************************************************************************/
/* BG */

.bg-hero
  content: ""
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: -1
  opacity: 1
  background-repeat: no-repeat
  background-size: cover
  background-attachment: scroll
  background-position: center top
  background-color: rgba(0,0,0,0.25)
  background-blend-mode: multiply
&.bg-cancha
  background-image: url(../img/bg-cancha.svg)
&.bg-landing
  background-image: url(../img/home-landing/jugafutbolcomonunca_2_mobile.jpg)
  background-position: center center;
&.bg-landing-jugapartido
  background-image: url(../img/home-landing/jugaunpartido_2_mobile.jpg)
  background-position: center 60%;
&.bg-landing-creapartido
  background-image: url(../img/home-landing/organizaunpartido_2_mobile.jpg)
  background-position: center center;
&.bg-landing-equipos
  background-image: url(../img/home-landing/jugaenequipos_2_mobile.jpg)
  background-position: center center;
&.bg-venues
  background-color: rgba(0,0,0,0.45)
  background-image: url(../img/home-landing/canchasycomplejos_2_mobile.jpg)
  background-position: center center;
&.bg-events
  background-image: url(../img/home-landing/eventoscorporativos_2_mobile.jpg)
  background-position: center bottom;

@media (min-width: 575px)
  .bg-landing
    background-image: url(../img/home-landing/jugafutbolcomonunca_1_tablet.jpg)
  .bg-landing-jugapartido
    background-image: url(../img/home-landing/jugaunpartido_1_tablet.jpg)
  .bg-landing-creapartido
    background-image: url(../img/home-landing/organizaunpartido_1_tablet.jpg)
  .bg-landing-equipos
    background-image: url(../img/home-landing/jugaenequipos_1_tablet.jpg)
  &.bg-venues
    background-image: url(../img/home-landing/canchasycomplejos_1_tablet.jpg)
  &.bg-events
    background-image: url(../img/home-landing/eventoscorporativos_1_tablet.jpg)

@media (min-width: 992px)
  .bg-landing
    background-image: url(../img/home-landing/jugafutbolcomonunca.jpg)
  .bg-landing-jugapartido
    background-image: url(../img/home-landing/jugaunpartido.jpg)
  .bg-landing-creapartido
    background-image: url(../img/home-landing/organizaunpartido.jpg)
  .bg-landing-equipos
    background-image: url(../img/home-landing/jugaenequipos.jpg)
  &.bg-venues
    background-image: url(../img/home-landing/canchasycomplejos.jpg)
  &.bg-events
    background-image: url(../img/home-landing/eventoscorporativos.jpg)


.bg-pattern-abstract
	background-image: url(../img/patterns/abstract.svg)
	background-color: white
	background-repeat: repeat
	background-position: center 0
	background-size: cover
	&.corporate 
		background-image: url(../img/patterns/abstract-corporate.svg)

.bg-pattern-futbol
	background-image: url(../img/patterns/pattern-don-final.gif)
	background-color: white
	background-repeat: repeat
	background-position: center center
	background-size: 250px
	&.bg-alternative
    background-blend-mode: multiply
    background-color: $color-secondary
		background-image: url(../img/patterns/pattern-don-final.gif)

.bg-black
	background-color: $color-dark
.bg-primary
	background-color: $color-primary !important
.bg-secondary
	background-color: $color-secondary !important

.border-primary
	border-color: $color-primary !important
.border-secondary
	border-color: $color-secondary !important

/*********************************************************************************************************************************************/
/* Iconos especiales */

.admin
  margin-right: 0.2rem
  i.icon-user-admin
    color: $color-warning-dark
    background-color: white
    border-radius: 50%
    font-size: 20px
    border: 1px solid $color-grey-2
  img
    width: 24px !important
    height: 24px !important

img
  &.icon-sm
    height: 16px !important
  &.icon-md
    height: 24px !important
  &.icon-lg
    height: 32px !important
  &.icon-xl
    height: 48px !important

/* GENERAL CLASSES ******************************************/
.w-100
  width: 100% !important
.relative
  position: relative
.hidden 
  display: none

.flex-grow-1
  flex-grow: 1

.d-two-columns
  column-count: 2
  gap: 1rem

.gap-1
    display: flex
    flex-wrap: wrap
    gap: 0.25rem

.clickable-card
  cursor: pointer;

.isDisabled
  pointer-events: none
  opacity: 0.3

.shadow-none
  box-shadow: 0 0 !important

.shadow-sm
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important

.shadow
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important
  
.shadow-lg
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important

.opacity-0
  opacity: 0
.opacity-1
  opacity: 0.2
.opacity-2
  opacity: 0.4
.opacity-3
  opacity: 0.6
.opacity-4
  opacity: 0.8
.opacity-5
  opacity: 1

@media (min-width: 768px)
  .scroll-with-mt
    scroll-margin-top: 76px 

/**********************************************************************/
.list-group-item
  &.active
  &:active
    background: $color-primary
    border-color: $color-primary
    color: white !important

.dropdown-menu
  min-width: 275px !important
  a
    justify-content: left

/* PROGRESS BAR GROW ON LOAD ******************************************/
@keyframes showProgress {
    0% {
        width: 0
    }
    100% {
        width: 100%
    }
}

.progreso-label
  .block
    display: inline-block
    border-radius: 50%
    width: 0.5rem
    height: 0.5rem
    margin-right: .25rem
    &.value-empty
      background-color: $color-grey-1
    &.value-0
      background-color: $color-primary
    &.value-25
      background-color: $color-secondary
    &.value-50
      background-color: $color-warning-dark
    &.value-75
      background-color: $color-danger
    &.value-100
      background-color: $color-dark-light

.progreso
    width: 100%
    background: $color-grey-1
    -webkit-border-radius: 25px
    -moz-border-radius: 25px
    border-radius: 25px
    display: block
    overflow: hidden
    .contenedor-barra
      display: block
      height: 4px
    span.progreso-barra
      display: block
      height: 4px
      width: auto
      &.value-0
        background-color: $color-primary
      &.value-25
        background-color: $color-secondary
      &.value-50
        background-color: $color-warning-dark
      &.value-75
        background-color: $color-danger
      &.value-100
        background-color: $color-dark-light
      &.animated
        width: 0
        animation-name: showProgress
        animation-delay: 0.3s
        animation-fill-mode: forwards
        animation-duration: 0.7s
        animation-iteration-count: 1
        animation-timing-function: ease-out



/* GENERAL COMPONENTS ******************************************/
code
  display: block
div
  &.codeBlock
  &:not(.codeBlock) > code
    border: 1px solid $color-grey-2
    border-radius: 0.25rem
    padding: 0.5rem
    margin-bottom: 1rem
  
.close-x
  i
    color: rgba(0,0,0,0.45)
    font-size: 32px

.copyClipboard
  position: relative
  small
    position: absolute !important
    display: none
    left: 50% !important
    top: -10px !important
    transform: translateX(-50%) !important
  span
    font-size: 0.6rem
    font-weight: bold
    text-transform: uppercase

a.ayuda
    width: 22px !important
    height: 22px !important
    border-radius: 50%
    padding: 2px
    outline: none
    display: inline-flex
    justify-content: center
    align-items: center

i.ayuda
  color: $color-primary


.breadcrumb
  font-size: 0.8rem
  background-color: transparent
  margin-bottom: 1rem
  padding: 0
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  flex-wrap: nowrap

.nav-tabs
  border-bottom: 1px solid $color-grey-1 !important

/*********************************************************************************************************************************************/
/* Modals */
.modal-body
  text-align: center

#install-app-banner
  .modal-footer
    border: 0
    background-color: $color-secondary

@media (max-width: 575px)
  .modal-dialog
    margin: 1rem


/*********************************************************************************************************************************************/
/* Card */

.card-outline
  background: transparent
  border: 2px dashed $color-grey-1
  border-radius: 0.25rem
  aspect-ratio: 2 / 1
  width: 100%
  margin-bottom: 20px

.card, .modal-dialog
  box-shadow: 0 0 15px rgba(0,0,0,0.15)
  h1, h2, h3, h4, h5, h6
    color: $color-dark
  h2
    font-size: 1.2rem
  .card-header, .modal-header
    background-color: white
    .card-title, .modal-title
      margin-bottom: 0
    h6
      margin-bottom: 0
      font-size: 0.7em
      text-transform: uppercase
      color: $color-grey-5
  .card-footer
    background-color: unset



/*********************************************************************************************************************************************/
/* Listas */
/*********************************************************************************************************************************************/
ul
  &.numbers
    padding: 0
    margin: 20px 0
    counter-reset: step
    li
      list-style-type: none
      font-size: 1em
      position relative
      display: block
      text-align: left
      margin-bottom: 1rem
      padding-left: 2rem
      &:before
        content: counter(step)
        counter-increment: step
        width: 24px;
        height: 24px;
        line-height: 24px;
        display: inline-flex
        align-items: center
        justify-content: center
        font-size: 0.85em
        font-weight: bold
        border-radius: 50%
        border: 1.5px solid
        margin: 0 15px 0 0
        background: $color-primary-light
        color: white
        position: absolute
        left: 0

/*********************************************************************************************************************************************/
/* PAGINATION */
ul.pagination {
  margin-top: 2rem;
  width: 100%;
  justify-content: center;
}
ul.pagination li {
    margin: 0 0.125rem;
}
ul.pagination li,
ul.pagination li a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    height: 1.25rem;
    font-size: 0.8rem;
}

ul.pagination li a {
  background: $color-primary;
  color: white !important;
  border-radius: 0.25rem;
}

/*********************************************************************************************************************************************/
/* TABLES */

.table.borderless
  tr
    th
    td
      border: none
.table-centered
  tr
    th
    td
      vertical-align: middle
      text-align: center

/*********************************************************************************************************************************************/
/* BADGES */
.badge
    font-size: 0.694rem
    text-transform: uppercase
    background: white
    &.badge-primary
        background: $alert-background-primary
        color: $color-text
        border: 1px solid $color-primary
        i
          color: $color-text
    &.badge-secondary
        background: $alert-background-secondary
        color: $color-text
        border: 1px solid $color-secondary
        i
          color: $color-text
    &.badge-success
        background: $alert-background-success
        color: $alert-color-success
        border: 1px solid $alert-border-success
        i
          color: $alert-color-success
    &.badge-info
        background: $alert-background-info
        color: $alert-color-info
        border: 1px solid $alert-border-info
        i
          color: $alert-color-info
    &.badge-warning
        background: $alert-background-warning
        color: $alert-color-warning
        border: 1px solid $alert-border-warning
        i
          color: $alert-color-warning
    &.badge-danger
        background: $alert-background-danger
        color: $alert-color-danger
        border: 1px solid $alert-border-danger
        i
          color: $alert-color-danger
    &.badge-light
        background: $alert-background-light
        color: $alert-color-light
        border: 1px solid $alert-border-light
        i
          color: $alert-color-light
    &.badge-dark
        background: $alert-background-dark
        color: $alert-color-dark
        border: 1px solid $alert-border-dark
        i
          color: $alert-color-dark
    &.pronto-arrow
        font-family: 'lato', sans-serif
        position: relative
        color: #222
        text-shadow: 0 1px rgba(255,255,255,0.2)
        background-color: transparent
        &::after
            content: ""
            background-image: url(../img/icon-illustrations/arrow.svg)
            width: 17px
            height: 21px
            overflow: hidden
            position: absolute
            left: -19px
            top: -7px
            transform: rotate(82deg)
            z-index: 0
        &.pronto-right
            &::after
                right: -19px !important
                left: unset
                top: -7px
                transform: rotate(-90deg) scaleX(-1)


.alert
  padding: 0.75rem
  &.alert-primary
      background: $alert-background-primary
      color: $color-text !important
      > i
          color: $color-text !important
  &.alert-secondary
      background: $alert-background-secondary
      color: $color-text !important
      > i
          color: $color-text !important
  &.alert-success
      background: $alert-background-success
      border-color: $alert-border-success
      color: $alert-color-success !important
      > i
          color: $alert-color-success !important
  &.alert-info
      background: $alert-background-info
      border-color: $alert-border-info
      color: $alert-color-info !important
      > i
          color: $alert-color-info !important
  &.alert-warning
      background: $alert-background-warning
      border-color: $alert-border-warning
      color: $alert-color-warning !important
      > i
          color: $alert-color-warning !important
  &.alert-danger
      background: $alert-background-danger
      border-color: $alert-border-danger
      color: $alert-color-danger !important
      > i
          color: $alert-color-danger !important
  &.alert-light
      background: $alert-background-light
      border-color: $alert-border-light
      color: $alert-color-light !important
      > i
          color: $alert-color-light !important
  &.alert-dark
      background: $alert-background-dark
      border-color: $alert-border-dark
      color: $alert-color-dark !important
      > i
          color: $alert-color-dark !important


/*********************************************************************************************************************************************/
/* ALERTAS Y NOTIFICACIONES */
/*********************************************************************************************************************************************/
section.messages
  margin-top: -4px
  box-shadow: inset 0 -2px 5px -1px rgba(0,0,0,0.4)

.toast
    position: fixed
    z-index: 9999
    width: calc(100% - 2rem)
    max-width: 500px
    bottom: 1rem
    right: 1rem
    .alert-don
        border-radius: 0.25em;
        overflow: hidden;
        box-shadow: 0 1rem 1.5rem rgba(0,0,0,0.3)
        margin-bottom: 1rem
        position: relative
        .alert-don-body
            display: flex
            align-items: stretch
            &.success
                background: $alert-background-success
                border-color: $alert-border-success
                color: $alert-color-success !important
                i
                    color: $alert-color-success !important
            &.info
                background: $alert-background-info
                border-color: $alert-border-info
                color: $alert-color-info !important
                i
                    color: $alert-color-info !important
            &.warning
                background: $alert-background-warning
                border-color: $alert-border-warning
                color: $alert-color-warning !important
                i
                    color: $alert-color-warning !important
            &.danger
                background: $alert-background-danger
                border-color: $alert-border-danger
                color: $alert-color-danger !important
                i
                    color: $alert-color-danger !important
            .action-toast
                padding: 8px 8px 0 0
                // background: white
            span
                // color: $color-dark-dark !important
                // background-color: white
                padding: 15px 20px 15px 0
                flex-grow: 1
            i:not(.icon-action-close)
                font-size: 2rem
                padding: 0.5rem
                display: inline-flex
                align-items: center

@media (min-width: 600px)
  .toast
    // max-width: 300px
      

// notificationes dentro de los partidos del estilo "Falta 1 dia a prepararse"
.static-notification
  display: flex
  flex-direction: row
  align-items: center
  border-radius: .25em
  box-shadow: 0 0 15px rgba(0,0,0,0.2)
  padding: 0.65em
  margin: 0 0 20px 0
  background: #f9fcf9
  border: 0
  color: $color-dark
  i
    margin-right: 0.55rem
    color: black !important
  p
    margin-bottom: 0
    small
      opacity: 0.75
      font-size: 90%
  &.status-primary
    border-top: 5px solid $color-primary-light
    i
      color: $color-primary !important
  &.status-secondary
    border-top: 5px solid $color-grey-3
    i
      color: $color-grey-4 !important
  &.status-info
    border-top: 5px solid $color-info
    i
      color: $color-info !important
  &.status-danger
    border-top: 5px solid $color-danger-light
    i
      color: $color-danger !important
  &.status-warning
    border-top: 5px solid $color-warning-light
    i
      color: $color-warning-dark !important
  &.status-dark
    border-top: 5px solid $color-dark-light
    i
      color: $color-dark-light !important


/*********************************************************************************************************************************************/
/* GOOGLEMAPS */
/*********************************************************************************************************************************************/
    
/*style the box*/  
.gm-style .gm-style-iw {
  max-width: 400px !important;
  width: calc(100vw - 3rem);
  /* border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: 3px solid #2e8149; */
  font-family: "Lato", sans-serif;
  font-weight: 400;
  padding: 0 !important;
}
.gm-style .gm-style-iw-t::after {
  display: none;
}
/*style the p tag*/
.gm-style .gm-style-p{
  padding: 0px;
}
.gm-style-iw-d {
  min-width: 100% !important;
  padding: 0;
  overflow: hidden !important;
}
.gm-style-iw-d h3 {
  width: calc(100% - 3rem);
}
/*style the arrow*/
.gm-style p {
    font-size: 16px;
}
// .gm-style input[type=button] {
//   background-color: #2e8149 !important;
//   padding: .25rem .5rem;
//   font-size: .875rem;
//   line-height: 1.5;
//   border-radius: .2rem;
//   border: 0;
// }
.gm-ui-hover-effect {
  top: 1rem !important;
  right: 1rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  position: relative;
}
.gm-ui-hover-effect img {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 1.5rem !important;
  height: 1.5rem !important;
  top: 50%;
  left: 50%;
  margin: 0 !important;
}

.window-map {
  padding: 1rem;
}