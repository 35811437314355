

.form-100
	width: 100%

.form-row
	margin-right: 0
	margin-left: 0
	margin-bottom: 20px !important

textarea
	resize: vertical
	min-height: 100px
	line-height: 120% !important

textarea &, 
select &, 
input[type="text"] &,
input[type="email"] &,
input[type="tel"] &,
input[type="number"] &,
input[type="password"] &,
input[type="date"] &,
input[type="time"] &,
.form-control
	border-radius: 5px
	border: 1px solid $color-grey-2
	background: white
	padding: 0px 10px
	min-height: 42px
	line-height: 1.5rem !important

textarea:disabled &, 
select:disabled &, 
input[type="text"]:disabled &,
input[type="email"]:disabled &,
input[type="tel"]:disabled &,
input[type="number"]:disabled &,
input[type="password"]:disabled &,
input[type="date"]:disabled &,
input[type="time"]:disabled &
	background: $color-grey-1

::placeholder
	font-style: italic !important
	color: $color-text-placeholder


textarea:focus &,
select:focus &,
input:focus
	border-color: #90a497
	box-shadow: 0 0 8px #a5cab0
	outline: 2px solid $color-secondary-light

.picker__header
	select
		font-size: 16px !important
		padding: 5px !important
	.picker__year
		font-style: unset
		font-size: unset


.container-input
	&.error > input, &.error > select, &.error > textarea
		border-color: $color-danger
	&.success > input, &.success > select, &.success > textarea
		border-color: $color-primary

.input-group
	display: flex
	flex-wrap: nowrap
	input
		width: 100%
		border-radius: 5px 0 0 5px !important
		&:not(:first-child)
			border-radius: 0 5px 5px 0 !important

.input-group-append
	display: flex

.input-group-append > :last-child
	border-radius: 0 5px 5px 0 !important


.input-group-text
	display: flex
	justify-content: center
	min-width: 31px
	padding: 0 0.25rem !important
	background: white

.input-group-text,
span.input-group-text i
	color: $alert-color-light


.minus-plus span.input-group-text
	max-width: 31px
	cursor: pointer
	background: $color-grey-1

.minus-plus span.input-group-text i
	font-size: 32px

.minus-plus span.input-group-text:active 
	background-color: $color-grey-2
	box-shadow: inset 0 1px 4px 0 rgba(0,0,0,0.3)

.minus-plus span.input-group-text:first-child
	border-right: 0
	margin-left: 0

label
	font-weight: 700
	font-size: 0.9rem
	color: $color-text-muted
	margin-bottom: .5rem
	width: 100%
	&.required
		position: relative
		padding-left: 10px
		&::before
		&::after
			color: #be1414
			font-weight: bold
		&::before
			content: "*"
			margin-right: 5px
			position: absolute
			top: 2px
			left: 0px
			font-size: 1.2rem
		&::after
			content: "- OBLIG."
			letter-spacing: 0.3px
			font-weight: bolder
			margin-left: 4px
			font-size: 0.5rem
			position: absolute
			top: 5px


label.inline {
	width: unset;
	flex-grow: 1;
	margin-bottom: 0;
}


label.as-btn,
label.checkbox,
label.radio,
label.switch {
	width: unset;
	// margin-bottom: 0;
}
label.as-btn,
label.checkbox,
label.radio {
	color: $color-dark;
	margin-left: 0;
	font-size: 1rem;
	font-weight: 500;
}
// label.as-btn,
// label.checkbox,
// label.radio  {
// 	margin-left: 5px;
// }


label.as-btn input, 
label.checkbox input, 
label.radio input, 
label.switch input {
	display: none;
}
label.as-btn span, 
label.checkbox span,
label.radio span,
label.switch span {
	cursor: pointer;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

/* AS-BTN Y AS-BTN+CIRCLE */
label.as-btn {
	font-weight: 700;
}
label.as-btn span {
	display:block;
	font-size: 0.9rem !important;
	border-radius: 50px;
	border: 1px solid $color-grey-3;
	height: auto !important;
	padding: 0.5rem 0.75rem !important;
	// text-align: center;
	background-color: white;
}
label.as-btn span:hover {
	border: 1px solid $color-primary;
	background-color: $color-grey-1
}
label.as-btn.disabled,
label.as-btn.disabled span  {
	pointer-events: none;
}

label.as-btn input:checked + span {
	border: 1px solid $color-primary;
	background-color: $color-primary-light;
	color:white /* $color-dark; */
	// box-shadow: inset 0 1px 4px 0 rgba(0,0,0,0.3);
}

label.as-btn.disabled span {
	border: 1px solid $color-grey-3;
	background-color: $color-grey-1
	color: $color-grey-3
}

label.as-btn.disabled input:checked + span {
	border: 1px solid $color-grey-1;
	background-color: $color-grey-3;
	color: $color-grey-1;
}
 

label.as-btn.circle span {
	width: 30px;
	height: 30px !important;
	padding: 0 !important;
	line-height: 30px;
}


/* CHECKBOX Y RADIO */
label.checkbox,
label.radio {
  position: relative;
  padding-left: 30px;
  line-height: 26px;
  margin-right: 10px;
}
label.checkbox span,
label.radio span {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  border: 1px solid $color-grey-3;
  background-color: white;
}
label.checkbox span {
  border-radius: 10px;
}
label.radio span {
  border-radius: 50%;
}
// label.checkbox:hover input ~ span,
// label.radio:hover input ~ span {
//   background-color: #ccc;
// }
label.checkbox input:checked ~ span,
label.radio input:checked ~ span {
  background-color: $color-primary-light;
}

label.checkbox input:disabled ~ span,
label.radio input:disabled ~ span {
  background-color: $color-grey-1;
}

label.checkbox input:disabled:checked ~ span,
label.radio input:disabled:checked ~ span {
  background-color: $color-grey-3;
}

label.checkbox span:after,
label.radio span:after {
  content: "";
  position: absolute;
  display: none;
}
label.checkbox input:checked ~ span:after,
label.radio input:checked ~ span:after {
  display: block;
}
label.checkbox span:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
label.radio span:after {
	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
	transform: translate(-50%, -50%);
}




/* SWITCH */
label.switch {
  position: relative;
  display: inline-block;
  max-width: 40px;
  min-width: 40px;
  height: 23px;
  margin-bottom 0;
}

label.switch span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b4d7c0;
  -webkit-transition: .4s;
  transition: .4s;
}
label.switch.pronto span {
	background-color #dbcfcf;
}
label.switch.pronto::after {
	content: "PRONTO";
    position: absolute;
    font-size: 0.7rem;
    background: $alert-background-info
    color: $alert-color-info
    padding: 0.3rem;
    border-radius: 50px;
    bottom: -8px;
    right: -5px;
    transform: rotate(3deg);
}

label.switch span:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

label.switch input:checked + span {
  background-color: #41a160;
}


label.switch input:checked + span:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

label.switch span {
  border-radius: 28px;
}

label.switch span:before {
  border-radius: 50%;
}


































/* NEW FORMS BY RENZO */
	

input[type="radio"],
input[type="checkbox"] {
	width: 1.2rem;
	height: 1.2rem;
	vertical-align: middle;
}

#mdl-textfield-venue-name {
	position relative;
}

#suggest_venue_address {
	position: absolute;
    z-index: 1;
    top: 15px;
    left: 15px;
    right: 15px;
    width: calc(100% - 30px);
    border: 1px solid #666;
}

#user-search {
	position: relative;
	display: inline-block;
	margin-left: 0;
	width: auto;
	/* min-width: 300px; */
}
#user-search input {
	/* min-width: 300px; */
	display: inline-block;
	padding-left: 5px;
}
#clear-searchbox {
	position: absolute;
	transform: translateY(-50%);
	top: 50%;
	right: 0px;
	background-color: transparent;
	border: 0; 
	width: 40px;
	height: 40px;

}
#clear-searchbox i {
	color: $color-primary;
	line-height: 40px;
}




.form-row {
	position: relative;
	text-align: left;
	margin-bottom:15px;
}


.field-notification {
	margin-top: 0.35em;
	font-size: 0.9em;
	color: $color-danger;
	font-weight: bold
	display: flex;
	align-items: flex-start;
}
.field-notification i {
	color: $color-danger;
}
.field-notification span {
    margin-top: 3px;
}








/******************************************************/
/** SLIDERS **/
input[type=range] {
  border: 0 !important;
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
/**********************************************************************/
/** CHROME ************************************************************/
/**********************************************************************/
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px !important;
  cursor: pointer;
  animate: 0.2s;
  background: $color-primary;
  border-radius: 1.3px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: $color-primary;
}
input[type=range]::-webkit-slider-thumb {
  height: 30px;
  width: 20px;
  border: 0 !important;
  background-color: transparent;
  background-position: center; 
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}
input.star[type=range]::-webkit-slider-thumb {
  background-image: url('../img/icon-illustrations/il_star.svg');
}
input.ball[type=range]::-webkit-slider-thumb {
  background-image: url('../img/icon-illustrations/il_ball.svg');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center 5px;
}
/**********************************************************************/
/** MOZILA ************************************************************/
/**********************************************************************/
input[type=range]::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  background: $color-primary;
  border-radius: 1.3px;
}
input[type=range]::-moz-range-thumb {
  height: 30px;
  width: 20px;
  border: 0 !important;
  background-color: transparent;
  background-position: center;
  cursor: pointer;
}
input.star[type=range]::-moz-range-thumb {
  background-image: url('../img/icon-illustrations/il_star.svg');
}
input.ball[type=range]::-moz-range-thumb {
  background-image: url('../img/icon-illustrations/il_ball.svg');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center 5px;
}
/**********************************************************************/
/** IEXPLORER**********************************************************/
/**********************************************************************/
input[type=range]::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  background: $color-primary;
  border-radius: 3px;
  color: transparent;
}

input[type=range]::-ms-thumb {
  height: 30px;
  width: 20px;
  border: 0 !important;
  background-color: transparent;
  background-position: center;
  cursor: pointer;
}
input.star[type=range]::-ms-thumb {
  background-image: url('../img/icon-illustrations/il_star.svg');
}
input.ball[type=range]::-ms-thumb {
  background-image: url('../img/icon-illustrations/il_ball.svg');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center 5px;
}
/*input[type=range]:focus::-ms-fill-lower {
  background: $color-primary;
}
input[type=range]:focus::-ms-fill-upper {
  background: $color-primary;
}*/
