// USERS
.user-avatar-name
  display: flex
  align-items: center

// USERS AVATARS
.avatar-don
    border-radius: 50%
    -webkit-border-radius: 50%
    -moz-border-radius: 50%
    overflow: hidden
    background-color: $color-grey-1
    display: inline-flex
    flex-shrink: 0
    align-items: center
    justify-content: center
    background-size: cover
    background-position: center
    vertical-align: unset
    margin-right: 0.35rem
    &.no-bg
      background: none !important
    &.av-xs
      width: 16px
      height: 16px
    &.av-sm
      width: 24px
      height: 24px
    &.av-md
      width: 32px
      height: 32px
    &.av-lg
      width: 48px
      height: 48px
    &.av-xl
      width: 64px
      height: 64px
    &.av-xxl
      width: 100px
      height: 100px
    &.unknown-player
      &::after
        content: "?"
        font-weight: 900
        font-size: 1rem
        color: $color-grey-3
.avatar-group
  display: inline-flex
  flex-direction: row-reverse
  align-items: center
  .avatar-don
    margin-right: -8px !important
    border: 1px solid white

.holder-vue
  margin-bottom: 2rem
.box-friends
  padding: 1rem
  margin-bottom: 3rem
  border: 1px solid $color-grey-2
  background-color: white
  border-radius: .25rem
  .holder-vue
    margin-bottom: 0 !important

.user-search
    vertical-align:top
    line-height:36px
    input
        width:100%
    button
        padding-left:5px
        width: 40px


.user-search-results {
    position: absolute;
    z-index: 1;
    width: 100%;
    max-height: 350px;
    padding: 0rem 0rem 2.5rem;
    overflow: hidden;
    display: flex;
    background-color: #fff;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
}
.user-search-results > div {
    max-height: 100%;
    overflow-y: auto;
    padding: 1rem;
    width: 100%;
    position: relative;
}
.user-search-results .clean-search {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width 100%;
    background: white;
    z-index: 1;
    border-top: 1px solid $color-grey-1;
}

.box-user
    width: 100%
    margin-bottom: 0.25rem
    .inner-user
        display: flex
        align-items: center
        padding: 0.5rem
        border-radius: 0.25rem
        &:hover
            background-color: hsl(135, 20%, 92%)
        .user-info
            flex-grow: 1
            display: flex
            align-items: center
            .user-username
                span
                    display: -webkit-box
                    -webkit-line-clamp: 2
                    -webkit-box-orient: vertical  
                    overflow: hidden
                    width: 100%
    

.board-players {
  display: flex;
  flex-direction: column;
}
.board-players img.ball {
  width: 26px !important;
}
.board-players .board-players-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.board-players .player {
  flex-grow: 1;
}
.board-players .player a,
.board-players .player span.unknown-player {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.board-players .player span.user-username{
  color: $color-primary;
}
.board-players .player span:not(.user-username) {
  color: #292929;
}

.board-players .board-players-row .board-players-cell {
  display: inline-flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
//   line-height: 40px;
  min-width: 40px;
}
.board-players .board-players-row .board-players-cell a {
  display: inline-flex;
  align-items: center;
}



      

  


